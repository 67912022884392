import styled from '@emotion/styled'
import BgImage from 'components/BgImage'
import Body from 'components/Body'
import Heading from 'components/Heading'

export const MenuItemOptionSquareView = styled.div<{ showGroups?: boolean }>`
  position: relative;
  width: ${(props) => (props.showGroups ? '100%' : '25%')};
  padding: 0 0.6rem;
  margin: 0 0 1.2rem;
  @media (max-width: ${(props) => props.theme.breakpoints.mobile}) {
    width: ${(props) => (props.showGroups ? '100%' : '33.33333%')};
  }
`

export const MenuItemOptionSquareCount = styled.div`
  position: absolute;
  z-index: 2;
  top: -0.8rem;
  right: -0.2rem;

  & > div {
    color: ${(props) => props.theme.success.color};
    border-color: ${(props) => props.theme.success.bgColor};
    background-color: ${(props) => props.theme.success.bgColor};
  }
`

export const MenuItemOptionSquareRemove = styled.button`
  position: absolute;
  z-index: 2;
  width: 4.4rem;
  height: 4.4rem;
  top: -1.1rem;
  left: -0.5rem;
  display: flex;
  justify-content: center;
  align-items: center;
  color: ${(props) => props.theme.modifiers.title.selectedColor};

  &:hover {
    opacity: 0.5;
  }
`

export const MenuItemOptionSquareRemoveIcon = styled.div`
  width: 2rem;
  height: 2rem;
  border-radius: 1rem;
  display: flex;
  justify-content: center;
  align-items: center;
`

export const MenuItemOptionSquareButton = styled.button<{
  showGroups?: boolean
}>`
  width: 100%;
  height: ${(props) => (props.showGroups ? 'auto' : '100%')};
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  padding: 1rem 0 0.8rem;
  border-radius: ${(props) => props.theme.modifiers.box.borderRadius};
  border-style: solid;
  border-width: ${(props) => props.theme.modifiers.box.border};
  border-color: ${(props) => props.theme.modifiers.colors.borderColor};
  background-color: ${(props) => props.theme.modifiers.colors.bgColor};
  box-shadow: ${(props) => props.theme.modifiers.box.boxShadow};
  // opacity: ${(props) => (props.disabled ? '0.5' : '1')};

  @media (max-width: ${(props) => props.theme.breakpoints.mobile}) {
    border-width: ${(props) => props.theme.modifiers.box.borderMobile};
  }

  &.show-hover:not(.selected):hover,
  &.show-hover:not(.selected):active {
    border-color: ${(props) => props.theme.modifiers.hoverColors.borderColor};
    background-color: ${(props) => props.theme.modifiers.hoverColors.bgColor};
  }

  &.selected:disabled {
    opacity: 1;
  }

  &.selected {
    border-color: ${(props) =>
      props.theme.modifiers.selectedColors.borderColor};
    background-color: ${(props) =>
      props.theme.modifiers.selectedColors.bgColor};
  }
`

export const MenuItemOptionSquareImageContainer = styled.div`
  flex-grow: 0;
  flex-shrink: 0;
  width: 100%;
  height: 6rem;
  padding: 0 1.5rem;
  margin: 0.5rem 0 1rem;
  display: flex;
  flex-direction: column;
  align-items: center;
`

export const MenuItemOptionImage = styled(BgImage)`
  position: relative;
  flex-grow: 0;
  flex-shrink: 0;
  width: 6rem;
  height: 6rem;
  border-radius: ${(props) => props.theme.border.radiusSmall};
  background-color: ${(props) => props.theme.bgColors.tertiary};
  overflow: hidden;
  @media (max-width: ${(props) => props.theme.breakpoints.mobile}) {
    width: 6rem;
    height: 6rem;
  }
`

export const MenuItemOptionSquareText = styled.div<{ showImage?: boolean }>`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  padding: 0 0.3rem;
`

export const MenuItemOptionSquareName = styled(Heading)`
  transition: ${(props) => props.theme.modifiers.box.transition};
  font-size: ${(props) => props.theme.modifiers.title.fontSize};
  color: ${(props) => props.theme.modifiers.title.color};

  button.show-hover:not(.selected):hover & {
    color: ${(props) => props.theme.modifiers.title.hoverColor};
  }

  button.selected & {
    color: ${(props) => props.theme.modifiers.title.selectedColor};
  }
`

export const MenuItemOptionSquareDesc = styled(Body)`
  margin: 0.4rem 0 0;
  transition: ${(props) => props.theme.modifiers.box.transition};
  font-size: ${(props) => props.theme.modifiers.text.fontSize};
  color: ${(props) => props.theme.modifiers.text.color};

  button.show-hover:not(.selected):hover & {
    color: ${(props) => props.theme.modifiers.text.hoverColor};
  }

  button.selected & {
    color: ${(props) => props.theme.modifiers.text.selectedColor};
  }
`

export const MenuItemOptionSquareSoldOut = styled(Body)`
  margin: 0.5rem 0 0;
  color: ${(props) => props.theme.colors.error};
`

export const MenuItemOptionOverlay = styled.div<{ overlay: string }>`
  position: absolute;
  z-index: 1;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  line-height: 1;
  border-radius: ${(props) => props.theme.border.radiusSmall};
  background-color: ${(props) => props.theme.overlay[props.overlay]};

  span {
    line-height: 1;
    color: ${(props) => props.theme.colors.light};
    font-size: ${(props) => props.theme.fonts.sizes.xSmall};
  }
`

export const MenuItemOptionOverlayAlert = styled.div`
  width: 2.2rem;
  height: 2.2rem;
  color: ${(props) => props.theme.colors.light};
`

export const MenuItemOptionSquareNested = styled.div`
  padding: 2rem ${(props) => props.theme.item.desktop.padding};
  background-color: ${(props) => props.theme.cards.modifier.bgColor};
  border-radius: ${(props) => props.theme.cards.modifier.borderRadius};
  border-top-left-radius: 0;
  border-top-right-radius: 0;
  @media (max-width: ${(props) => props.theme.breakpoints.tablet}) {
    padding: 2rem ${(props) => props.theme.item.mobile.padding};
  }

  & > div > div {
    margin: 0;
    padding-bottom: 2rem;
    border-radius: ${(props) => props.theme.cards.modifier.borderRadius};
    @media (max-width: ${(props) => props.theme.breakpoints.tablet}) {
      padding-bottom: 2rem;
    }
  }
`
