import styled from '@emotion/styled'
import { CartItemGroup, CartItemOption, CartLevels } from '@open-tender/types'
import { useModifier } from '@open-tender/utils'
import {
  selectDisplaySettings,
  selectSelectedAllergenNames,
  useAppSelector,
} from '@open-tender/cloud'
import { Body } from 'components'
import Collapsible from '../../Collapsible'
import Quantity from '../../Quantity'
import MenuItemPizza from '../MenuItemPizza'
import MenuItemFullGroups from './MenuItemFullGroups'
import MenuItemFullOptionDisplay from './MenuItemFullOptionDisplay'

const MenuItemFullOptionView = styled.div<{ showGroups?: boolean }>`
  position: relative;
  width: ${(props) => (props.showGroups ? '100%' : '33.33333%')};
  padding: ${(props) => (props.showGroups ? '0' : '1rem')};
`

// const MenuItemFullOptionInfo = styled.button`
//   position: absolute;
//   z-index: 1;
//   bottom: 0.5rem;
//   right: 0.5rem;
//   width: 4rem;
//   height: 4rem;
//   // background-color: pink;
//   display: flex;
//   justify-content: center;
//   align-items: center;
//   color: ${(props) => props.theme.colors.tertiary};
//   color: #aaa;
// `

const MenuItemFullOptionContainer = styled.div<{ showGroups?: boolean }>`
  width: ${(props) => (props.showGroups ? '33.33333%' : '100%')};
  padding: ${(props) => (props.showGroups ? '1rem' : '0')};
`

const MenuItemFullOptionButton = styled.button`
  label: MenuItemFullOptionButton;
  display: block;
  width: 100%;
`

const MenuItemOptionSoldOut = styled(Body)`
  width: 9.2rem;
  min-width: 9.2rem;
  padding: 1.5rem;
  text-align: center;
  color: ${(props) => props.theme.colors.error};
  font-size: ${(props) => props.theme.fonts.sizes.main};
  @media (max-width: ${(props) => props.theme.breakpoints.mobile}) {
    font-size: ${(props) => props.theme.fonts.sizes.small};
  }
`

const MenuItemFullOptionPizza = styled.div`
  padding-right: 1.5rem;
`

const MenuItemFullOption = ({
  group,
  option,
  levels = [],
  toggleOption,
  incrementOption,
  decrementOption,
  setOptionQuantity,
  index,
}: {
  group: CartItemGroup
  option: CartItemOption
  levels: CartLevels
  toggleOption: (levels: CartLevels) => void
  incrementOption: (levels: CartLevels) => void
  decrementOption: (levels: CartLevels) => void
  setOptionQuantity: (levels: CartLevels, quantity: number) => void
  index: number
}) => {
  const allergenAlerts = useAppSelector(selectSelectedAllergenNames)
  const displaySettings = useAppSelector(selectDisplaySettings)
  const modifier = useModifier(
    group,
    option,
    levels,
    toggleOption,
    incrementOption,
    decrementOption,
    setOptionQuantity,
    allergenAlerts,
    displaySettings
  )
  const {
    isSoldOut,
    optionGroups,
    hasInfo,
    isCheckbox,
    showGroups,
    toggle,
    adjust,
    increment,
    decrement,
    incrementDisabled,
    decrementDisabled,
    updatedLevels,
    quantity,
  } = modifier

  return (
    <MenuItemFullOptionView showGroups={showGroups}>
      <MenuItemFullOptionContainer showGroups={showGroups}>
        {isSoldOut ? (
          <MenuItemFullOptionDisplay modifier={modifier}>
            <MenuItemOptionSoldOut>Out of Stock</MenuItemOptionSoldOut>
          </MenuItemFullOptionDisplay>
        ) : optionGroups && group.isPizza ? (
          <MenuItemFullOptionDisplay modifier={modifier}>
            <MenuItemFullOptionPizza>
              <MenuItemPizza
                option={option}
                levels={updatedLevels}
                increment={increment}
                decrement={decrement}
                adjust={adjust}
                incrementDisabled={incrementDisabled}
                decrementDisabled={decrementDisabled}
                toggleOption={toggleOption}
                incrementOption={incrementOption}
                decrementOption={decrementOption}
                isVertical={true}
              />
            </MenuItemFullOptionPizza>
          </MenuItemFullOptionDisplay>
        ) : group.max === 1 && !hasInfo ? (
          <MenuItemFullOptionButton
            onClick={quantity === 1 ? decrement : toggle}
          >
            <MenuItemFullOptionDisplay modifier={modifier} />
          </MenuItemFullOptionButton>
        ) : group.max === 1 ? (
          <MenuItemFullOptionDisplay modifier={modifier} />
        ) : (
          <MenuItemFullOptionDisplay modifier={modifier}>
            <Quantity
              item={option}
              increment={increment}
              decrement={decrement}
              adjust={adjust}
              incrementDisabled={incrementDisabled}
              decrementDisabled={decrementDisabled}
              showAdd={true}
              isCheckbox={isCheckbox}
            />
          </MenuItemFullOptionDisplay>
        )}
      </MenuItemFullOptionContainer>
      {optionGroups && !group.isPizza ? (
        <Collapsible show={showGroups}>
          <MenuItemFullGroups
            groups={option.groups}
            levels={updatedLevels}
            toggleOption={toggleOption}
            incrementOption={incrementOption}
            decrementOption={decrementOption}
            setOptionQuantity={setOptionQuantity}
            isChild={true}
          />
        </Collapsible>
      ) : null}
    </MenuItemFullOptionView>
  )
}

export default MenuItemFullOption
