import { useCallback, useEffect, useState } from 'react'
import { CartItemOption, CartItemOptions, CartLevels } from '@open-tender/types'
import MenuItemPizzaLeft from './MenuItemPizzaLeft'
import MenuItemPizzaRight from './MenuItemPizzaRight'
import MenuItemPizzaWhole from './MenuItemPizzaWhole'
import {
  MenuItemPizzaContainer,
  MenuItemPizzaToggle,
  MenuItemPizzaView,
} from './MenuItemPizza.styled'
import MenuItemPizzaAmount from './MenuItemPizzaAmount'

const nestedMap = {
  LEFT: '1st Half',
  RIGHT: '2nd Half',
  WHOLE: 'Whole',
}

const makeNestedLookup = (options?: CartItemOptions) => {
  if (!options) return null
  return Object.entries(nestedMap).reduce((obj, [k, v]) => {
    const option = options.find((i) => i.name === v)
    if (!option) return obj
    return { ...obj, [k]: option.id }
  }, {} as Record<string, number>)
}

const MenuItemPizza = ({
  option,
  levels,
  increment,
  decrement,
  adjust,
  incrementDisabled,
  decrementDisabled,
  toggleOption,
  decrementOption,
  isVertical = false,
}: {
  option: CartItemOption
  levels: CartLevels
  increment: () => void
  decrement: () => void
  adjust: (quantity: number) => void
  incrementDisabled: boolean
  decrementDisabled: boolean
  toggleOption: (levels: CartLevels) => void
  incrementOption: (levels: CartLevels) => void
  decrementOption: (levels: CartLevels) => void
  isVertical?: boolean
}) => {
  const [defaultSelected, setDefaultSelected] = useState(false)
  const pizzaGroup = option.groups.find((g) => g.isPizza)
  const groupId = pizzaGroup?.id
  const nestedLookup = makeNestedLookup(pizzaGroup?.options)
  const selected = pizzaGroup?.options?.find((i) => i.quantity === 1)
  const selectedId = selected ? selected.id : null
  const isDisabled = incrementDisabled && option.quantity === 0
  const otherGroup = option.groups.find((g) => !g.isPizza)
  const otherOptions = otherGroup?.options
  const otherGroupId = otherGroup?.id
  const selectedOther = otherGroup?.options?.find((i) => i.quantity === 1)
  const selectedOtherId = selectedOther ? selectedOther.id : null
  const wholeId = nestedLookup?.WHOLE

  const handleOption = (optionId: number | null) => {
    if (!groupId || !optionId) return
    const updatedLevels: CartLevels = [...levels, [groupId, optionId]]
    if (selectedId === optionId) {
      decrementOption(updatedLevels)
    } else {
      if (!incrementDisabled || option.quantity > 0) toggleOption(updatedLevels)
    }
  }

  const handleOther = useCallback(
    (optionId: number) => {
      if (!otherGroupId || !optionId) return
      const updatedLevels: CartLevels = [...levels, [otherGroupId, optionId]]
      toggleOption(updatedLevels)
    },
    [levels, otherGroupId, toggleOption]
  )

  // const handleDecrement = () => {
  //   option.quantity === 1 ? handleOption(selectedId) : decrement()
  // }

  useEffect(() => {
    if (option.isDefault && wholeId && !selectedId && !defaultSelected) {
      if (!groupId || !wholeId) return
      const updatedLevels: CartLevels = [...levels, [groupId, wholeId]]
      toggleOption(updatedLevels)
    }
  }, [
    option.isDefault,
    wholeId,
    selectedId,
    defaultSelected,
    groupId,
    levels,
    nestedLookup?.WHOLE,
    toggleOption,
  ])

  useEffect(() => {
    if (option.isDefault && selectedId) {
      setDefaultSelected(true)
    }
  }, [option.isDefault, selectedId])

  useEffect(() => {
    if (selectedId) {
      if (option.quantity === 0) increment()
    } else {
      if (option.quantity > 0) adjust(0)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedId, option.quantity])

  if (!pizzaGroup) return null

  return (
    <MenuItemPizzaView>
      <MenuItemPizzaContainer isVertical={isVertical}>
        {/* {option.quantity > 0 && (
        <Quantity
          item={option}
          increment={increment}
          decrement={handleDecrement}
          adjust={adjust}
          incrementDisabled={incrementDisabled}
          decrementDisabled={decrementDisabled}
          showAdd={true}
          isCheckbox={false}
        />
      )} */}
        {nestedLookup && (
          <MenuItemPizzaToggle isDisabled={isDisabled}>
            {otherOptions && (
              <MenuItemPizzaAmount
                quantity={option.quantity}
                options={otherOptions}
                changeOption={handleOther}
                selectedId={selectedOtherId}
              />
            )}
            <MenuItemPizzaLeft
              onClick={() => handleOption(nestedLookup.LEFT)}
              isSelected={selectedId === nestedLookup.LEFT}
              isDisabled={isDisabled}
            />
            <MenuItemPizzaWhole
              onClick={() => handleOption(nestedLookup.WHOLE)}
              isSelected={selectedId === nestedLookup.WHOLE}
              isDisabled={isDisabled}
            />
            <MenuItemPizzaRight
              onClick={() => handleOption(nestedLookup.RIGHT)}
              isSelected={selectedId === nestedLookup.RIGHT}
              isDisabled={isDisabled}
            />
          </MenuItemPizzaToggle>
        )}
      </MenuItemPizzaContainer>
    </MenuItemPizzaView>
  )
}

export default MenuItemPizza
