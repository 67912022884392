import styled from '@emotion/styled'
import { CornerDownRight } from 'react-feather'
import { CartItemGroup } from '@open-tender/types'
import { useModifierGroup } from '@open-tender/utils'
import { selectDisplaySettings, useAppSelector } from '@open-tender/cloud'
import { Heading } from 'components'

const MenuItemGroupHeaderAltView = styled.div<{ isChild?: boolean }>`
  display: flex;
  margin: ${(props) => (props.isChild ? '0' : '0 0 1rem')};
  justify-content: space-between;
  align-items: flex-end;
`

const MenuItemGroupHeaderAltArrow = styled.span`
  position: relative;
  top: 0.3rem;
`

const MenuItemGroupHeaderAltName = styled(Heading)<{ isChild?: boolean }>`
  margin-bottom: 0.5rem;
  line-height: 1.2;
  font-size: ${(props) =>
    props.isChild ? props.theme.fonts.sizes.main : props.theme.fonts.sizes.big};

  @media (max-width: ${(props) => props.theme.breakpoints.tablet}) {
    font-size: ${(props) =>
      props.isChild
        ? props.theme.fonts.sizes.small
        : props.theme.fonts.sizes.main};
  }
`

const MenuItemGroupHeaderAltNameQuantity = styled.span<{
  belowMin?: boolean
  belowIncluded?: boolean
}>`
  padding: 0 0 0 0.5rem;
  ${(props) =>
    props.belowMin
      ? `color: ${props.theme.colors.error};`
      : props.belowIncluded
      ? `color: ${props.theme.colors.success};`
      : ''}
`

const MenuItemGroupHeaderAltDescription = styled.p`
  font-size: ${(props) => props.theme.fonts.sizes.small};
  @media (max-width: ${(props) => props.theme.breakpoints.tablet}) {
    font-size: ${(props) => props.theme.fonts.sizes.xSmall};
  }
`

const MenuItemGroupHeaderAlt = ({
  group,
  isChild = false,
}: {
  group: CartItemGroup
  isChild?: boolean
}) => {
  const displaySettings = useAppSelector(selectDisplaySettings)
  const { name, description, count, quantity, min, included } =
    useModifierGroup(group, displaySettings)

  return (
    <MenuItemGroupHeaderAltView isChild={isChild}>
      <div>
        <MenuItemGroupHeaderAltName as="p" isChild={isChild}>
          {isChild ? (
            <MenuItemGroupHeaderAltArrow>
              <CornerDownRight size={18} />
            </MenuItemGroupHeaderAltArrow>
          ) : null}{' '}
          {name}
          <MenuItemGroupHeaderAltNameQuantity
            belowMin={quantity < min}
            belowIncluded={(quantity ?? 0) < (included ?? 0)}
          >
            ({count})
          </MenuItemGroupHeaderAltNameQuantity>
        </MenuItemGroupHeaderAltName>
        {description && (
          <MenuItemGroupHeaderAltDescription>
            {description}
          </MenuItemGroupHeaderAltDescription>
        )}
      </div>
    </MenuItemGroupHeaderAltView>
  )
}

export default MenuItemGroupHeaderAlt
