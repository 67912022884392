import styled from '@emotion/styled'
import { toppingAlignItems, toppingJustifyContent } from './utils'
import {Coverage} from "./types";

export const PizzaBuilderToppingView = styled.div<{coverage: Coverage}>`
  position: absolute;
  top: 5%;
  left: 5%;
  width: 90%;
  height: 90%;
  overflow: hidden;
  border-radius: 50%;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  transform: rotateY(0deg) rotate(${props => props.coverage === 'whole' ? '30deg': '90deg'});  
`

export const PizzaBuilderToppingImageContainer = styled.div<{
  index: number
  squareSize: number
}>`
  display: flex;
  position: relative;
  width: ${(props) => `${100 / props.squareSize}%`};
  aspect-ratio: 1/1;
  align-items: ${(props) => toppingAlignItems[props.index % 5]};
  justify-content: ${(props) => toppingJustifyContent[props.index % 5]};
`

export const PizzaBuilderToppingImage = styled.img<{pizzaSize: number}>`
  width: ${props => `${Math.ceil(props.pizzaSize / 20)}px`};
  min-width: ${props => `${Math.ceil(props.pizzaSize / 20)}px`};
  min-height: ${props => `${Math.ceil(props.pizzaSize / 20)}px`};
  height: ${props => `${Math.ceil(props.pizzaSize / 20)}px`};
  position: absolute;
  object-fit: cover;
  scale: 3;
  display: block;
`
