import styled from '@emotion/styled'
import Button from '../../Button'

export const MenuItemPizzaView = styled.div<{ isVertical?: boolean }>`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
`

export const MenuItemPizzaContainer = styled.div<{ isVertical?: boolean }>`
  height: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: ${(props) => (props.isVertical ? 'column-reverse' : 'row')};
  gap: ${(props) => (props.isVertical ? '0.75rem' : '2rem')};
  margin: ${(props) => (props.isVertical ? '1rem 0 0' : '0 0.8rem 0 0')};
`

export const MenuItemPizzaToggle = styled.div<{ isDisabled?: boolean }>`
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 6px;
  opacity: ${(props) => (props.isDisabled ? '0.25' : '1')};
`

export const MenuItemPizzaAmountView = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin-right: 10px;

  button {
    transform: scale(0.8);
    margin: 0 -0.4rem;
  }

  button:disabled {
    color: ${(props) => props.theme.buttons.colors.secondary.bgColor};
    background-color: ${(props) => props.theme.buttons.colors.secondary.color};
    opacity: 1;
  }
`

export const MenuItemPizzaAmountButton = styled(Button)`
  background-color: ${(props) =>
    props.disabled
      ? props.theme.buttons.colors.primary.bgColor
      : props.theme.buttons.colors.secondary.bgColor} !important;
  border-color: ${(props) =>
    props.disabled
      ? props.theme.buttons.colors.primary.bgColor
      : props.theme.buttons.colors.secondary.bgColor} !important;
  color: ${(props) =>
    props.disabled
      ? props.theme.buttons.colors.primary.color
      : props.theme.buttons.colors.secondary.color} !important;

  &:hover {
    background-color: ${(props) =>
      props.disabled
        ? props.theme.buttons.colors.primary.bgColor
        : props.theme.buttons.colors.secondaryHover.bgColor} !important;
    border-color: ${(props) =>
      props.disabled
        ? props.theme.buttons.colors.primary.bgColor
        : props.theme.buttons.colors.secondaryHover.bgColor} !important;
    color: ${(props) =>
      props.disabled
        ? props.theme.buttons.colors.primary.color
        : props.theme.buttons.colors.secondaryHover.color} !important;
  }
`
