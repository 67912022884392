import { useTheme } from '@emotion/react'
import * as RF from 'react-feather'

export const AlertCircle = (props: React.ComponentPropsWithRef<RF.Icon>) => {
  const { icons } = useTheme()
  return <RF.AlertCircle size={20} strokeWidth={icons.strokeWidth} {...props} />
}

export const AlertTriangle = (props: React.ComponentPropsWithRef<RF.Icon>) => {
  const { icons } = useTheme()
  return (
    <RF.AlertTriangle size={20} strokeWidth={icons.strokeWidth} {...props} />
  )
}

export const ArrowLeft = (props: React.ComponentPropsWithRef<RF.Icon>) => {
  const { icons } = useTheme()
  return <RF.ArrowLeft size={20} strokeWidth={icons.strokeWidth} {...props} />
}

export const ArrowRight = (props: React.ComponentPropsWithRef<RF.Icon>) => {
  const { icons } = useTheme()
  return <RF.ArrowRight size={20} strokeWidth={icons.strokeWidth} {...props} />
}

export const Award = (props: React.ComponentPropsWithRef<RF.Icon>) => {
  const { icons } = useTheme()
  return <RF.Award size={20} strokeWidth={icons.strokeWidth} {...props} />
}

export const Calendar = (props: React.ComponentPropsWithRef<RF.Icon>) => {
  const { icons } = useTheme()
  return <RF.Calendar size={20} strokeWidth={icons.strokeWidth} {...props} />
}

export const Check = (props: React.ComponentPropsWithRef<RF.Icon>) => {
  const { icons } = useTheme()
  return <RF.Check size={20} strokeWidth={icons.strokeWidth} {...props} />
}

export const CheckCircle = (props: React.ComponentPropsWithRef<RF.Icon>) => {
  const { icons } = useTheme()
  return <RF.CheckCircle size={20} strokeWidth={icons.strokeWidth} {...props} />
}

export const ChevronDown = (props: React.ComponentPropsWithRef<RF.Icon>) => {
  const { icons } = useTheme()
  return <RF.ChevronDown size={20} strokeWidth={icons.strokeWidth} {...props} />
}

export const ChevronLeft = (props: React.ComponentPropsWithRef<RF.Icon>) => {
  const { icons } = useTheme()
  return <RF.ChevronLeft size={20} strokeWidth={icons.strokeWidth} {...props} />
}

export const ChevronRight = (props: React.ComponentPropsWithRef<RF.Icon>) => {
  const { icons } = useTheme()
  return (
    <RF.ChevronRight size={20} strokeWidth={icons.strokeWidth} {...props} />
  )
}

export const ChevronUp = (props: React.ComponentPropsWithRef<RF.Icon>) => {
  const { icons } = useTheme()
  return <RF.ChevronUp size={20} strokeWidth={icons.strokeWidth} {...props} />
}

export const Clipboard = (props: React.ComponentPropsWithRef<RF.Icon>) => {
  const { icons } = useTheme()
  return <RF.Clipboard size={20} strokeWidth={icons.strokeWidth} {...props} />
}

export const Clock = (props: React.ComponentPropsWithRef<RF.Icon>) => {
  const { icons } = useTheme()
  return <RF.Clock size={20} strokeWidth={icons.strokeWidth} {...props} />
}

export const Coffee = (props: React.ComponentPropsWithRef<RF.Icon>) => {
  const { icons } = useTheme()
  return <RF.Coffee size={20} strokeWidth={icons.strokeWidth} {...props} />
}

export const CreditCard = (props: React.ComponentPropsWithRef<RF.Icon>) => {
  const { icons } = useTheme()
  return <RF.CreditCard size={20} strokeWidth={icons.strokeWidth} {...props} />
}

export const DollarSign = (props: React.ComponentPropsWithRef<RF.Icon>) => {
  const { icons } = useTheme()
  return <RF.DollarSign size={20} strokeWidth={icons.strokeWidth} {...props} />
}

export const Edit = (props: React.ComponentPropsWithRef<RF.Icon>) => {
  const { icons } = useTheme()
  return <RF.Edit size={20} strokeWidth={icons.strokeWidth} {...props} />
}

export const Edit2 = (props: React.ComponentPropsWithRef<RF.Icon>) => {
  const { icons } = useTheme()
  return <RF.Edit2 size={20} strokeWidth={icons.strokeWidth} {...props} />
}

export const ExternalLink = (props: React.ComponentPropsWithRef<RF.Icon>) => {
  const { icons } = useTheme()
  return (
    <RF.ExternalLink size={20} strokeWidth={icons.strokeWidth} {...props} />
  )
}

export const FileText = (props: React.ComponentPropsWithRef<RF.Icon>) => {
  const { icons } = useTheme()
  return <RF.FileText size={20} strokeWidth={icons.strokeWidth} {...props} />
}

export const Flag = (props: React.ComponentPropsWithRef<RF.Icon>) => {
  const { icons } = useTheme()
  return <RF.Flag size={20} strokeWidth={icons.strokeWidth} {...props} />
}

export const Gift = (props: React.ComponentPropsWithRef<RF.Icon>) => {
  const { icons } = useTheme()
  return <RF.Gift size={20} strokeWidth={icons.strokeWidth} {...props} />
}

export const Grid = (props: React.ComponentPropsWithRef<RF.Icon>) => {
  const { icons } = useTheme()
  return <RF.Grid size={20} strokeWidth={icons.strokeWidth} {...props} />
}

export const Heart = (props: React.ComponentPropsWithRef<RF.Icon>) => {
  const { icons } = useTheme()
  return <RF.Heart size={20} strokeWidth={icons.strokeWidth} {...props} />
}

export const Home = (props: React.ComponentPropsWithRef<RF.Icon>) => {
  const { icons } = useTheme()
  return <RF.Home size={20} strokeWidth={icons.strokeWidth} {...props} />
}

export const Info = (props: React.ComponentPropsWithRef<RF.Icon>) => {
  const { icons } = useTheme()
  return <RF.Info size={20} strokeWidth={icons.strokeWidth} {...props} />
}

export const Lock = (props: React.ComponentPropsWithRef<RF.Icon>) => {
  const { icons } = useTheme()
  return <RF.Lock size={20} strokeWidth={icons.strokeWidth} {...props} />
}

export const LogOut = (props: React.ComponentPropsWithRef<RF.Icon>) => {
  const { icons } = useTheme()
  return <RF.LogOut size={20} strokeWidth={icons.strokeWidth} {...props} />
}

export const Mail = (props: React.ComponentPropsWithRef<RF.Icon>) => {
  const { icons } = useTheme()
  return <RF.Mail size={20} strokeWidth={icons.strokeWidth} {...props} />
}

export const MapPin = (props: React.ComponentPropsWithRef<RF.Icon>) => {
  const { icons } = useTheme()
  return <RF.MapPin size={20} strokeWidth={icons.strokeWidth} {...props} />
}

export const Map = (props: React.ComponentPropsWithRef<RF.Icon>) => {
  const { icons } = useTheme()
  return <RF.Map size={20} strokeWidth={icons.strokeWidth} {...props} />
}

export const Menu = (props: React.ComponentPropsWithRef<RF.Icon>) => {
  const { icons } = useTheme()
  return <RF.Menu size={20} strokeWidth={icons.strokeWidth} {...props} />
}

export const Minus = (props: React.ComponentPropsWithRef<RF.Icon>) => {
  const { icons } = useTheme()
  return <RF.Minus size={20} strokeWidth={icons.strokeWidth} {...props} />
}

export const MinusCircle = (props: React.ComponentPropsWithRef<RF.Icon>) => {
  const { icons } = useTheme()
  return <RF.MinusCircle size={20} strokeWidth={icons.strokeWidth} {...props} />
}

export const Navigation = (props: React.ComponentPropsWithRef<RF.Icon>) => {
  const { icons } = useTheme()
  return <RF.Navigation size={20} strokeWidth={icons.strokeWidth} {...props} />
}

export const Phone = (props: React.ComponentPropsWithRef<RF.Icon>) => {
  const { icons } = useTheme()
  return <RF.Phone size={20} strokeWidth={icons.strokeWidth} {...props} />
}

export const Plus = (props: React.ComponentPropsWithRef<RF.Icon>) => {
  const { icons } = useTheme()
  return <RF.Plus size={20} strokeWidth={icons.strokeWidth} {...props} />
}

export const PlusCircle = (props: React.ComponentPropsWithRef<RF.Icon>) => {
  const { icons } = useTheme()
  return <RF.PlusCircle size={20} strokeWidth={icons.strokeWidth} {...props} />
}

export const RefreshCw = (props: React.ComponentPropsWithRef<RF.Icon>) => {
  const { icons } = useTheme()
  return <RF.RefreshCw size={20} strokeWidth={icons.strokeWidth} {...props} />
}

export const Save = (props: React.ComponentPropsWithRef<RF.Icon>) => {
  const { icons } = useTheme()
  return <RF.Save size={20} strokeWidth={icons.strokeWidth} {...props} />
}

export const Settings = (props: React.ComponentPropsWithRef<RF.Icon>) => {
  const { icons } = useTheme()
  return <RF.Settings size={20} strokeWidth={icons.strokeWidth} {...props} />
}

export const ShoppingBag = (props: React.ComponentPropsWithRef<RF.Icon>) => {
  const { icons } = useTheme()
  return <RF.ShoppingBag size={20} strokeWidth={icons.strokeWidth} {...props} />
}

export const ShoppingCart = (props: React.ComponentPropsWithRef<RF.Icon>) => {
  const { icons } = useTheme()
  return (
    <RF.ShoppingCart size={20} strokeWidth={icons.strokeWidth} {...props} />
  )
}

export const Slash = (props: React.ComponentPropsWithRef<RF.Icon>) => {
  const { icons } = useTheme()
  return <RF.Slash size={20} strokeWidth={icons.strokeWidth} {...props} />
}

export const Sliders = (props: React.ComponentPropsWithRef<RF.Icon>) => {
  const { icons } = useTheme()
  return <RF.Sliders size={20} strokeWidth={icons.strokeWidth} {...props} />
}

export const Smartphone = (props: React.ComponentPropsWithRef<RF.Icon>) => {
  const { icons } = useTheme()
  return <RF.Smartphone size={20} strokeWidth={icons.strokeWidth} {...props} />
}

export const Smile = (props: React.ComponentPropsWithRef<RF.Icon>) => {
  const { icons } = useTheme()
  return <RF.Smile size={20} strokeWidth={icons.strokeWidth} {...props} />
}

export const Star = (props: React.ComponentPropsWithRef<RF.Icon>) => {
  const { icons } = useTheme()
  return <RF.Star size={20} strokeWidth={icons.strokeWidth} {...props} />
}

export const Tag = (props: React.ComponentPropsWithRef<RF.Icon>) => {
  const { icons } = useTheme()
  return <RF.Tag size={20} strokeWidth={icons.strokeWidth} {...props} />
}

export const Trash2 = (props: React.ComponentPropsWithRef<RF.Icon>) => {
  const { icons } = useTheme()
  return <RF.Trash2 size={20} strokeWidth={icons.strokeWidth} {...props} />
}

export const Truck = (props: React.ComponentPropsWithRef<RF.Icon>) => {
  const { icons } = useTheme()
  return <RF.Truck size={20} strokeWidth={icons.strokeWidth} {...props} />
}

export const User = (props: React.ComponentPropsWithRef<RF.Icon>) => {
  const { icons } = useTheme()
  return <RF.User size={20} strokeWidth={icons.strokeWidth} {...props} />
}

export const Users = (props: React.ComponentPropsWithRef<RF.Icon>) => {
  const { icons } = useTheme()
  return <RF.Users size={20} strokeWidth={icons.strokeWidth} {...props} />
}

export const UserMinus = (props: React.ComponentPropsWithRef<RF.Icon>) => {
  const { icons } = useTheme()
  return <RF.UserMinus size={20} strokeWidth={icons.strokeWidth} {...props} />
}

export const UserPlus = (props: React.ComponentPropsWithRef<RF.Icon>) => {
  const { icons } = useTheme()
  return <RF.UserPlus size={20} strokeWidth={icons.strokeWidth} {...props} />
}

export const UserX = (props: React.ComponentPropsWithRef<RF.Icon>) => {
  const { icons } = useTheme()
  return <RF.UserX size={20} strokeWidth={icons.strokeWidth} {...props} />
}

export const XCircle = (props: React.ComponentPropsWithRef<RF.Icon>) => {
  const { icons } = useTheme()
  return <RF.XCircle size={20} strokeWidth={icons.strokeWidth} {...props} />
}

export const X = (props: React.ComponentPropsWithRef<RF.Icon>) => {
  const { icons } = useTheme()
  return <RF.X size={20} strokeWidth={icons.strokeWidth} {...props} />
}
