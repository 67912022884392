import { useEffect } from 'react'
import {
  fetchPointsShop,
  selectContent,
  selectCustomerTpls,
  selectPointsShop,
  useAppDispatch,
  useAppSelector,
} from '@open-tender/cloud'
import ScrollableItems from 'components/ScrollableItems'
import PointsShopReward from 'components/PointsShopReward'
import AccountSection from './AccountSection'

const AccountPointsShop = () => {
  const dispatch = useAppDispatch()
  const { entities: rewards, loading, error } = useAppSelector(selectPointsShop)
  const { pointsShop: config } = useAppSelector(selectContent) || {}
  const customerTpls = useAppSelector(selectCustomerTpls) || {}
  const { balance: pointsBalance = null } = customerTpls.tpls
    ? customerTpls.tpls.points || {}
    : {}

  useEffect(() => {
    dispatch(fetchPointsShop())
  }, [dispatch])

  if (error) return null

  return (
    <AccountSection>
      <ScrollableItems
        title={config?.title}
        to={rewards.length > 2 ? '/points-shop' : undefined}
        // to="/points-shop"
        isLoading={loading === 'pending'}
        loadingText="Loading point shop..."
      >
        {rewards.map((reward) => (
          <PointsShopReward
            key={reward.id}
            reward={reward}
            pointsBalance={pointsBalance}
          />
        ))}
      </ScrollableItems>
    </AccountSection>
  )
}

export default AccountPointsShop
