import { useEffect, useMemo, useState } from 'react'
import styled from '@emotion/styled'
import { useParams, Link, useNavigate } from 'react-router-dom'
import { Helmet } from 'react-helmet'
import { OrderType, RequestError, ServiceType } from '@open-tender/types'
import { makeFormErrors } from '@open-tender/utils'
import {
  fetchLocationBySlug,
  resetOrderType,
  selectBrand,
  selectContent,
  selectOrder,
  selectSettings,
  setGeoError,
  setGeoLatLng,
  setGeoLoading,
  setOrderServiceType,
  useAppDispatch,
  useAppSelector,
} from '@open-tender/cloud'
import {
  Container,
  Content,
  GoogleMap,
  GoogleMapsMarker,
  Header,
  HeaderLogo,
  Loading,
  Main,
  RevenueCenter as RevenueCenterCard,
  ScreenreaderTitle,
  useGeolocation,
} from 'components'
import { ClipLoader } from 'react-spinners'
import { NavMenu } from 'components/buttons'
import RevenueCenterButtons from './RevenueCenterButtons'
import RevenueCenterMap from './RevenueCenterMap'

const RevenueCenterView = styled.div`
  position: relative;
  z-index: 2;
  margin: 4rem 0 0;
  background-color: ${(props) => props.theme.bgColors.primary};
  @media (max-width: ${(props) => props.theme.breakpoints.tablet}) {
    margin: 38rem 0 0;
    padding: ${(props) => props.theme.layout.marginMobile} 0;
  }
  @media (max-width: ${(props) => props.theme.breakpoints.mobile}) {
    margin: 24rem 0 0;
    padding: ${(props) => props.theme.layout.marginMobile} 0;
    background-color: ${(props) => props.theme.bgColors.primary};
  }
`

const RevenueCenter = () => {
  const dispatch = useAppDispatch()
  const navigate = useNavigate()
  const { slug } = useParams()
  const { geoLatLng, geoError } = useGeolocation()
  const { title: siteTitle, pilot_slug } = useAppSelector(selectBrand) || {}
  const { revenueCenters: config } = useAppSelector(selectContent) || {}
  const { googleMaps } = useAppSelector(selectSettings) || {}
  const { apiKey, zoom = 14, styles, icons } = googleMaps || {}
  const {
    revenueCenter,
    address: userAddress,
    loading,
    error,
  } = useAppSelector(selectOrder)
  const isLoading = loading === 'pending'
  const { name, address } = revenueCenter || {}
  const title = name || config?.title
  const rcLatLng = useMemo(() => {
    if (address) {
      return { lat: address.lat, lng: address.lng }
    } else {
      return null
    }
  }, [address])
  const initialUserPosition = userAddress
    ? { lat: userAddress.lat, lng: userAddress.lng }
    : geoLatLng
  const [userPosition, setUserPosition] = useState(initialUserPosition)
  const [center, setCenter] = useState(rcLatLng)
  const errors = makeFormErrors(error as RequestError)
  const notFound =
    error && errors.form?.includes('does not exist') ? true : false

  useEffect(() => {
    dispatch(setGeoLoading())
    dispatch(resetOrderType())
    if (slug) dispatch(fetchLocationBySlug(slug))
  }, [dispatch, slug])

  useEffect(() => {
    if (notFound) {
      navigate(pilot_slug ? '/not-found' : '/account')
    }
  }, [pilot_slug, notFound, navigate])

  useEffect(() => {
    if (rcLatLng) {
      setCenter(rcLatLng)
    }
  }, [rcLatLng])

  useEffect(() => {
    if (geoLatLng) {
      dispatch(setGeoLatLng(geoLatLng))
      setUserPosition(geoLatLng)
    } else if (geoError) {
      dispatch(setGeoError(geoError))
    }
  }, [geoLatLng, geoError, dispatch])

  useEffect(() => {
    if (revenueCenter) {
      const { service_types, revenue_center_type } = revenueCenter
      let serviceType =
        service_types && service_types.length
          ? service_types.includes('PICKUP')
            ? 'PICKUP'
            : 'DELIVERY'
          : null
      if (serviceType) {
        dispatch(
          setOrderServiceType({
            orderType: (revenue_center_type ?? 'OLO') as OrderType,
            serviceType: serviceType as ServiceType,
          })
        )
      }
    }
  }, [revenueCenter, config?.background, dispatch])

  return (
    <>
      <Helmet>
        <title>
          {title} | {siteTitle}
        </title>
      </Helmet>
      <Content maxWidth="76.8rem">
        <Header maxWidth="76.8rem" left={<HeaderLogo />} right={<NavMenu />} />
        <Main>
          <ScreenreaderTitle>{title}</ScreenreaderTitle>
          {apiKey && styles && center ? (
            <GoogleMap
              apiKey={apiKey}
              zoom={zoom}
              styles={styles}
              center={center}
              loader={<ClipLoader size={30} loading={true} />}
              renderMap={(props) => <RevenueCenterMap {...props} />}
            >
              <RevenueCenterView>
                <Container>
                  {isLoading ? (
                    <Loading text="Retrieving location" />
                  ) : revenueCenter ? (
                    <RevenueCenterCard
                      revenueCenter={revenueCenter}
                      showImage={true}
                      isLanding={true}
                    />
                  ) : (
                    <p>
                      Location not found. Please try a different URL or{' '}
                      <Link to="/account">head back to our home page</Link>.
                    </p>
                  )}
                  {revenueCenter && (
                    <RevenueCenterButtons revenueCenter={revenueCenter} />
                  )}
                </Container>
              </RevenueCenterView>
              <GoogleMapsMarker
                title={title}
                position={center}
                icon={icons?.location.url}
                size={icons?.location.size}
                anchor={icons?.location.anchor}
              />
              <GoogleMapsMarker
                title="Your Location"
                position={userPosition}
                icon={icons?.user.url}
                size={icons?.user.size}
                anchor={icons?.user.anchor}
              />
            </GoogleMap>
          ) : (
            <></>
          )}
        </Main>
      </Content>
    </>
  )
}

export default RevenueCenter
