import { useEffect, useMemo, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { isMobile } from 'react-device-detect'
import { Helmet } from 'react-helmet'
import { CartItem } from '@open-tender/types'
import { makeMenuItemLookup, makeUpsellItems } from '@open-tender/utils'
import {
  selectBrand,
  selectCartIds,
  selectContentSection,
  selectCustomer,
  selectMenu,
  selectMenuSlug,
  useAppSelector,
} from '@open-tender/cloud'
import { Back, Skip } from 'components/buttons'
import {
  Button,
  ButtonLink,
  Content,
  Header,
  HeaderLogo,
  Main,
  UpsellItems,
} from 'components'
import {
  UpsellFooter,
  UpsellHeader,
  UpsellSubtitle,
  UpsellTitle,
  UpsellView,
} from './Upsell.styled'

const Upsell = () => {
  const navigate = useNavigate()
  const [itemAdded, setItemAdded] = useState(false)
  const { title: siteTitle } = useAppSelector(selectBrand) || {}
  const { auth } = useAppSelector(selectCustomer)
  const menuSlug = useAppSelector(selectMenuSlug)
  const cartIds = useAppSelector(selectCartIds)
  const { categories, soldOut } = useAppSelector(selectMenu)
  const itemLookup = useMemo(() => makeMenuItemLookup(categories), [categories])
  const upsells = useAppSelector(selectContentSection('upsells'))
  const { show, title, subtitle, decline, proceed } = upsells?.checkout || {}
  const menuItems = cartIds.map((id) => itemLookup[id ?? 0])
  const upsellItemIds: number[] = menuItems.reduce(
    (arr, i) => [...arr, ...(i?.upsell_items || i?.upsellItems || [])],
    []
  )
  const uniqueIds: number[] = Array.from(new Set(upsellItemIds))
  const itemIds = uniqueIds.filter(
    (id: number) => !cartIds.includes(id) && !soldOut.includes(id)
  )
  const upsellItems = makeUpsellItems(itemIds, itemLookup)
  const hasItems = upsellItems.length > 0
  const path = auth ? '/checkout' : '/checkout/guest'
  const skip = !show || !hasItems

  const checkout = () => navigate(path)

  useEffect(() => {
    if (skip) navigate(path)
  }, [skip, path, navigate])

  if (skip) return null

  return (
    <>
      <Helmet>
        <title>Add-ons | {siteTitle}</title>
      </Helmet>
      <Content>
        <Header
          title={<HeaderLogo />}
          left={
            <Back
              label="Back to Menu"
              path={menuSlug}
              text={isMobile ? null : 'Back to Menu'}
            />
          }
          right={<Skip path={path} text="Skip" />}
        />
        <Main>
          <UpsellView>
            <UpsellHeader>
              <UpsellTitle>{title}</UpsellTitle>
              {subtitle && <UpsellSubtitle>{subtitle}</UpsellSubtitle>}
            </UpsellHeader>
            <UpsellItems
              upsellItems={upsellItems as CartItem[]}
              isSmall={isMobile}
              addCallback={() => setItemAdded(true)}
            />
            <UpsellFooter>
              {itemAdded ? (
                <Button onClick={checkout}>{proceed}</Button>
              ) : (
                <ButtonLink onClick={checkout}>
                  {decline || 'No thanks'}
                </ButtonLink>
              )}
            </UpsellFooter>
          </UpsellView>
        </Main>
      </Content>
    </>
  )
}

export default Upsell
