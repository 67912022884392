import { useState, useEffect } from 'react'
import styled from '@emotion/styled'
import { RequestError } from '@open-tender/types'
import { makeFormErrors, makeNumeric } from '@open-tender/utils'
import {
  assignCustomerGiftCard,
  resetCustomerGiftCardsError,
  selectCustomerGiftCards,
  useAppDispatch,
  useAppSelector,
  validateOrder,
} from '@open-tender/cloud'
import { Button, FormError, Input } from 'components'

const CheckoutGiftCardNew = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 2rem 0 0;
`

const CheckoutGiftCardInput = styled.div`
  flex-grow: 1;
  flex-shrink: 1;
  padding: 0 2rem 0 0;
`

const CheckoutGiftCardButton = styled.div`
  flex-grow: 0;
  flex-shrink: 0;
`

const makeErrMsg = (error: RequestError) => {
  if (!error) return null
  const errors = makeFormErrors(error)
  return errors.card_number || errors.form
}

const CheckoutGiftCard = () => {
  const dispatch = useAppDispatch()
  const [cardNumber, setCardNumber] = useState('')
  const [error, setError] = useState('')
  const [submitting, setSubmitting] = useState(false)
  const callback = () => dispatch(validateOrder())
  const { loading, error: giftCardErr } = useAppSelector(
    selectCustomerGiftCards
  )
  const errMsg = makeErrMsg(giftCardErr)
  const disabled = submitting || !cardNumber ? true : false

  useEffect(() => {
    return () => {
      dispatch(resetCustomerGiftCardsError())
    }
  }, [dispatch])

  useEffect(() => {
    if (loading === 'idle' && submitting) {
      setSubmitting(false)
      if (!errMsg) setCardNumber('')
    }
  }, [loading, submitting, errMsg])

  useEffect(() => {
    if (errMsg) setError(errMsg)
  }, [errMsg])

  const handleChange = (evt: React.ChangeEvent<HTMLInputElement>) => {
    setError('')
    setCardNumber(makeNumeric(evt.target.value))
  }

  const applyCardNumber = () => {
    setSubmitting(true)
    dispatch(resetCustomerGiftCardsError())
    const cleanNumber = parseInt(makeNumeric(cardNumber))
    dispatch(assignCustomerGiftCard({ cardNumber: cleanNumber, callback }))
  }

  return (
    <>
      <CheckoutGiftCardNew>
        <CheckoutGiftCardInput>
          <Input
            type="number"
            name="card_number"
            label="Enter gift card number"
            value={cardNumber}
            onChange={handleChange}
            pattern="[0-9]*"
            style={{ margin: 0 }}
          />
        </CheckoutGiftCardInput>
        <CheckoutGiftCardButton>
          <Button
            onClick={applyCardNumber}
            disabled={disabled}
            size="small"
            color="secondary"
          >
            {submitting ? 'Adding to Account...' : 'Add to Account'}
          </Button>
        </CheckoutGiftCardButton>
      </CheckoutGiftCardNew>
      <FormError errMsg={error} />
    </>
  )
}

export default CheckoutGiftCard
