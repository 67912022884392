import styled from '@emotion/styled'
import { Heading, Text } from 'components'

interface CheckoutButtonViewProps {
  isApplied?: boolean
}

export const CheckoutButtonView = styled.button<CheckoutButtonViewProps>`
  position: relative;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 1rem 0 0;
  min-height: ${(props) => props.theme.buttons.sizes.large.minHeight};
  padding: ${(props) => props.theme.buttons.sizes.large.padding};
  padding-left: 0;
  padding-right: 0;
  border-style: solid;
  border-width: ${(props) => props.theme.buttons.sizes.large.borderWidth};
  border-radius: ${(props) => props.theme.buttons.sizes.large.borderRadius};
  background-color: ${(props) => props.theme.buttons.colors.large.bgColor};
  border-color: ${(props) =>
    props.isApplied
      ? props.theme.colors.success
      : props.theme.buttons.colors.large.borderColor};

  @media (max-width: ${(props) => props.theme.breakpoints.mobile}) {
    min-height: ${(props) => props.theme.buttons.sizes.large.minHeightMobile};
  }

  &:hover {
    background-color: ${(props) =>
      props.isApplied || props.disabled
        ? props.theme.buttons.colors.large.bgColor
        : props.theme.buttons.colors.largeHover.bgColor};
    border-color: ${(props) =>
      props.isApplied
        ? props.theme.colors.success
        : props.disabled
        ? props.theme.buttons.colors.large.borderColor
        : props.theme.buttons.colors.largeHover.borderColor};
  }
`

export const CheckoutButtonCheckmark = styled.div`
  position: absolute;
  z-index: 2;
  top: -0.8rem;
  right: -0.8rem;
`

export const CheckoutButtonInfo = styled.div`
  flex-grow: 1;
  flex-shrink: 1;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: 1.5rem 0 1.5rem 2rem;
  @media (max-width: ${(props) => props.theme.breakpoints.mobile}) {
    padding: 1rem 0 1rem 1.5rem;
  }
`

export const CheckoutButtonIcon = styled.div<CheckoutButtonViewProps>`
  flex-grow: 0;
  flex-shrink: 0;
  width: 3rem;
  margin: 0 2rem 0 0;
  display: flex;
  justify-content: center;
  align-items: center;
  color: ${(props) => props.theme.buttons.colors.large.iconColor};
  @media (max-width: ${(props) => props.theme.breakpoints.mobile}) {
    margin: 0 1.5rem 0 0;
  }

  button:enabled:hover & {
    color: ${(props) =>
      props.isApplied
        ? props.theme.buttons.colors.large.iconColor
        : props.theme.buttons.colors.largeHover.iconColor};
  }

  svg {
    width: 3rem;
  }
`

export const CheckoutButtonText = styled.div`
  flex-grow: 1;
  flex-shrink: 1;
  display: flex;
  flex-direction: column;

  & > span {
    display: block;
    text-align: left;
  }
`

export const CheckoutButtonTitle = styled(Heading)<CheckoutButtonViewProps>`
  transition: ${(props) => props.theme.links.transition};
  color: ${(props) => props.theme.buttons.colors.large.color};
  margin-top: ${(props) => props.theme.buttons.sizes.large.titleMarginTop};
  margin-left: ${(props) => props.theme.buttons.sizes.large.titleMarginLeft};
  transition: ${(props) => props.theme.links.transition};
  font-family: ${(props) => props.theme.buttons.sizes.large.family};
  font-weight: ${(props) => props.theme.buttons.sizes.large.weight};
  letter-spacing: ${(props) => props.theme.buttons.sizes.large.letterSpacing};
  line-height: 1.2;
  text-transform: ${(props) => props.theme.buttons.sizes.large.textTransform};
  -webkit-font-smoothing: ${(props) =>
    props.theme.buttons.sizes.large.fontSmoothing};
  font-size: ${(props) => props.theme.buttons.sizes.large.titleFontSize};

  @media (max-width: ${(props) => props.theme.breakpoints.mobile}) {
    margin-top: ${(props) =>
      props.theme.buttons.sizes.large.titleMarginTopMobile};
    margin-left: ${(props) =>
      props.theme.buttons.sizes.large.titleMarginLeftMobile};
    font-size: ${(props) =>
      props.theme.buttons.sizes.large.titleFontSizeMobile};
  }

  button:enabled:hover & {
    color: ${(props) =>
      props.isApplied
        ? props.theme.buttons.colors.large.color
        : props.theme.buttons.colors.largeHover.color};
  }
`

export const CheckoutButtonSubtitle = styled(Text)<CheckoutButtonViewProps>`
  transition: ${(props) => props.theme.links.transition};
  margin: 0.3rem 0 0;
  font-size: ${(props) => props.theme.fonts.sizes.small};
  color: ${(props) => props.theme.buttons.colors.large.subtitleColor};
  @media (max-width: ${(props) => props.theme.breakpoints.mobile}) {
    font-size: ${(props) => props.theme.fonts.sizes.xSmall};
  }

  button:enabled:hover & {
    color: ${(props) =>
      props.isApplied
        ? props.theme.buttons.colors.large.subtitleColor
        : props.theme.buttons.colors.largeHover.subtitleColor};
  }
`

interface CheckoutButtonButtonProps {
  disabled?: boolean
}

export const CheckoutButtonButton = styled.div<CheckoutButtonButtonProps>`
  flex-grow: 0;
  flex-shrink: 0;
  width: 12rem;
  height: 100%;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding: 0 2rem 0 0;
  opacity: ${(props) => (props.disabled ? '0.5' : '1')};
  @media (max-width: ${(props) => props.theme.breakpoints.mobile}) {
    width: 9rem;
    padding: 0 1.5rem 0 0;
  }
`

export const CheckoutButtonButtonIcon = styled.div<CheckoutButtonViewProps>`
  width: 1.8rem;
  height: 1.8rem;
  color: ${(props) => props.theme.buttons.colors.large.iconColor};
  @media (max-width: ${(props) => props.theme.breakpoints.mobile}) {
    width: 1.6rem;
    height: 1.6rem;
  }

  button:enabled:hover & {
    color: ${(props) =>
      props.isApplied
        ? props.theme.colors.error
        : props.theme.buttons.colors.largeHover.iconColor};
  }
`

export const CheckoutButtonApply = styled(Text)`
  display: block;
  line-height: 1;
  margin: 0 0 0 0.5rem;
  transition: ${(props) => props.theme.links.transition};
  color: ${(props) => props.theme.buttons.colors.large.iconColor};
  font-size: ${(props) => props.theme.fonts.sizes.small};
  @media (max-width: ${(props) => props.theme.breakpoints.mobile}) {
    margin: 0.1 0 0 0.4rem;
    font-size: ${(props) => props.theme.fonts.sizes.xSmall};
  }

  svg {
    transition: ${(props) => props.theme.links.transition};
  }

  button:enabled:hover & {
    color: ${(props) => props.theme.buttons.colors.largeHover.iconColor};
  }
`

export const CheckoutButtonRemove = styled(CheckoutButtonApply)`
  button:enabled:hover & {
    color: ${(props) => props.theme.colors.error};
  }
`
