import { BackgroundImage } from '../index'
import React, { ReactNode, useEffect, useState } from 'react'
import {
  MenuItemWrapperContent,
  MenuItemWrapperImage,
  MenuItemWrapperImagePlaceholder,
  MenuItemWrapperView,
} from './MenuItemWrapper.styled'
import { useTheme } from '@emotion/react'
import {
  selectBrand,
  selectCurrentItem,
  useAppSelector,
} from '@open-tender/cloud'

const MenuItemWrapper = ({
  pizzaBuilder,
  menuItem,
  showFullPage = false,
  isPizza = false,
}: {
  pizzaBuilder: ReactNode
  menuItem: ReactNode
  showFullPage?: boolean
  isPizza?: boolean
}) => {
  const theme = useTheme()
  const { imageBgSize } = theme.item.desktop
  const item = useAppSelector(selectCurrentItem)
  const { openGraphImage } = useAppSelector(selectBrand) || {}
  const [imageUrl, setImageUrl] = useState(item ? item.imageUrl : null)
  const imageUrlWithFallback = (imageUrl || item?.imageUrl) ?? null
  const showPlaceholder = !!(item && !imageUrlWithFallback && openGraphImage)

  useEffect(() => {
    if (!item?.imageUrl) return
    setImageUrl(item.imageUrl)
  }, [item?.imageUrl, openGraphImage])

  if (showFullPage) return <>{menuItem}</>

  return (
    <MenuItemWrapperView>
      {isPizza ? (
        pizzaBuilder
      ) : (
        <MenuItemWrapperImage>
          {showPlaceholder ? (
            <MenuItemWrapperImagePlaceholder>
              <img src={openGraphImage || undefined} alt={item.name} />
            </MenuItemWrapperImagePlaceholder>
          ) : (
            <BackgroundImage
              imageUrl={imageUrlWithFallback}
              bgSize={imageBgSize}
            />
          )}
        </MenuItemWrapperImage>
      )}
      <MenuItemWrapperContent>{menuItem}</MenuItemWrapperContent>
    </MenuItemWrapperView>
  )
}

export default MenuItemWrapper
