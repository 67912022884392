import pizza from 'assets/images/pizza.png'
import {
  LoyaltyVisitsPizza,
  LoyaltyVisitsPizzaContainer,
  LoyaltyVisitsPizzasView,
} from './LoyaltyVisitsPizzas.styled'

const LoyaltyVisitsPizzas = ({
  threshold,
  progress,
}: {
  threshold: string
  progress: string
}) => {
  const pizzas = Array.from(Array(parseInt(threshold)).keys())

  return (
    <LoyaltyVisitsPizzasView>
      {pizzas.map((i) => {
        const isFilled = i < parseInt(progress)
        return (
          <LoyaltyVisitsPizzaContainer key={i}>
            <LoyaltyVisitsPizza src={pizza} isFilled={isFilled} />
          </LoyaltyVisitsPizzaContainer>
        )
      })}
    </LoyaltyVisitsPizzasView>
  )
}

export default LoyaltyVisitsPizzas
