import { CartItemOptions } from '@open-tender/types'
import {
  MenuItemPizzaAmountButton,
  MenuItemPizzaAmountView,
} from './MenuItemPizza.styled'

const MenuItemPizzaAmount = ({
  quantity,
  options,
  changeOption,
  selectedId,
}: {
  quantity: number
  options: CartItemOptions
  changeOption: (optionId: number) => void
  selectedId: number | null
}) => {
  if (quantity === 0) return <></>

  return (
    <MenuItemPizzaAmountView>
      {options.map((option) => (
        <MenuItemPizzaAmountButton
          key={option.id}
          onClick={() => changeOption(option.id)}
          size="small"
          color="secondary"
          disabled={selectedId === option.id}
        >
          {option.name}
        </MenuItemPizzaAmountButton>
      ))}
    </MenuItemPizzaAmountView>
  )
}

export default MenuItemPizzaAmount
