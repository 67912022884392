import { useEffect, useState } from 'react'
import { Outlet, useNavigate } from 'react-router-dom'
import { RequestedAt } from '@open-tender/types'
import {
  fetchAllergens,
  fetchAnnouncementPage,
  fetchCustomerFavorites,
  fetchCustomerLoyalty,
  fetchCustomerOrders,
  fetchLocation,
  fetchMenu,
  fetchTags,
  openModal,
  selectCustomer,
  selectGroupOrderClosed,
  selectIsGroupOrder,
  // selectMenuVars,
  selectOrder,
  setIsGroupOrder,
  useAppDispatch,
  useAppSelector,
} from '@open-tender/cloud'
import SidebarModal from '../../SidebarModal'
import { ItemSidebar } from '../../sidebarModals'

const MenuPage = () => {
  const navigate = useNavigate()
  const dispatch = useAppDispatch()
  const [init, setInit] = useState(true)
  const { orderType, serviceType, requestedAt, revenueCenter } =
    useAppSelector(selectOrder)
  const revenueCenterId = revenueCenter?.revenue_center_id
  // const { revenueCenterId, serviceType, requestedAt } =
  //   useAppSelector(selectMenuVars)
  const groupOrderClosed = useAppSelector(selectGroupOrderClosed)
  const { profile } = useAppSelector(selectCustomer)
  const { customer_id = null } = profile || {}
  const [customerId, setCustomerId] = useState<number | null>(null)
  const isGroupOrder = useAppSelector(selectIsGroupOrder)

  useEffect(() => {
    if (!revenueCenterId) {
      return navigate('/locations')
    } else if (groupOrderClosed) {
      return navigate('/review')
    } else if (init) {
      setInit(false)
      dispatch(fetchAllergens())
      dispatch(fetchTags())
      dispatch(fetchLocation(revenueCenterId))
      dispatch(
        fetchMenu({
          revenueCenterId,
          serviceType: serviceType ?? 'WALKIN',
          requestedAt: (requestedAt as RequestedAt) ?? 'asap',
        })
      )
      dispatch(fetchAnnouncementPage('MENU'))
    }
  }, [
    init,
    revenueCenterId,
    orderType,
    serviceType,
    requestedAt,
    groupOrderClosed,
    dispatch,
    navigate,
  ])

  useEffect(() => {
    if (customer_id) {
      if (customer_id !== customerId) {
        setCustomerId(customer_id)
        dispatch(fetchCustomerLoyalty())
        dispatch(fetchCustomerFavorites())
        dispatch(fetchCustomerOrders(20))
      }
    } else {
      setCustomerId(null)
    }
  }, [customer_id, customerId, dispatch])

  useEffect(() => {
    if (isGroupOrder) {
      const reviewOrders = () => navigate(`/review`)
      dispatch(openModal({ type: 'groupOrder', args: { reviewOrders } }))
      dispatch(setIsGroupOrder(false))
    }
  }, [isGroupOrder, dispatch, navigate])

  return (
    <>
      <Outlet />
      <SidebarModal>
        <ItemSidebar />
      </SidebarModal>
    </>
  )
}

export default MenuPage
