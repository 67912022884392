import styled from '@emotion/styled'

export const LoyaltyVisitsPizzasView = styled.div`
  margin: 2rem -0.8rem;
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(12.5%, 1fr));

  @media (max-width: 768px) {
    grid-template-columns: repeat(auto-fill, minmax(25%, 1fr));
  }
`
export const LoyaltyVisitsPizzaContainer = styled.div`
  padding: 0.8rem;
`

export const LoyaltyVisitsPizza = styled.img<{ isFilled?: boolean }>`
  opacity: ${(props) => (props.isFilled ? '100%' : '25%')};
  // width: 100px;
`

export const LoyaltyVisitsPizzasCount = styled.span`
  font-weight: 800;
  color: ${(props) => props.theme.colors.alert};
`
