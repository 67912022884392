import { useNavigate } from 'react-router-dom'
import { TPLS } from '@open-tender/types'
import { ButtonLink } from 'components'
import Heading from 'components/Heading'
import Body from 'components/Body'
import ThirdPartyLoyaltyProgress from './ThirdPartyLoyaltyProgress'
import {
  TPLSSummary,
  TPLSSummaryContent,
  TPLSSummaryInfo,
  TPLSSummaryPoints,
  TPLSSummaryPointsHeader,
  TPLSSummaryStatus,
  TPLSSummaryStatusSep,
} from './ThirdPartyLoyalty.styled'
import { Star } from 'components/icons'
import { formatDollars, formatQuantity } from '@open-tender/utils'

const ThirdPartyLoyaltySummary = ({
  isAccount,
  first_name,
  last_name,
  phone,
  email,
  credit,
  points,
  progress,
  membership_tier,
}: TPLS & { isAccount: boolean }) => {
  const navigate = useNavigate()
  const pointsBalance = points ? points.balance : null
  const creditBalance = credit ? credit.balance : null
  const hasCredit = creditBalance && parseFloat(creditBalance) ? true : false
  const showSeparator = pointsBalance !== null && hasCredit

  return (
    <TPLSSummary isAccount={isAccount}>
      <TPLSSummaryContent>
        <TPLSSummaryInfo>
          <Heading as="p" size="xBig" style={{ marginLeft: -1 }}>
            {first_name} {last_name}
          </Heading>
          <Body as="p" size="xSmall">
            {phone}
          </Body>
          <Body as="p" size="xSmall">
            {email}
          </Body>
          {membership_tier && (
            <Heading as="p" size="small" style={{ marginTop: 10 }}>
              Membership Tier: {membership_tier || 'n/a'}
            </Heading>
          )}
          <Body as="p" size="xSmall" style={{ marginTop: 10 }}>
            <ButtonLink onClick={() => navigate('/account/profile')}>
              Update your profile
            </ButtonLink>
          </Body>
        </TPLSSummaryInfo>
        <TPLSSummaryStatus>
          {hasCredit && creditBalance ? (
            <TPLSSummaryPoints>
              <TPLSSummaryPointsHeader>
                {/* <Star width={36} height={36} strokeWidth={1.5} /> */}
                <Heading size="xxBig">
                  {formatDollars(creditBalance.toString())}
                </Heading>
              </TPLSSummaryPointsHeader>
              <Body size="xSmall">Credit Balance</Body>
              <Body size="xSmall" style={{ marginTop: 5 }}>
                <ButtonLink onClick={() => navigate('/order-type')}>
                  Use your credit
                </ButtonLink>
              </Body>
            </TPLSSummaryPoints>
          ) : null}
          {showSeparator ? <TPLSSummaryStatusSep /> : null}
          {pointsBalance !== null ? (
            <TPLSSummaryPoints>
              <TPLSSummaryPointsHeader>
                <Star width={36} height={36} strokeWidth={1.5} />
                <Heading size="xxBig">
                  {formatQuantity(pointsBalance.toString())}
                </Heading>
              </TPLSSummaryPointsHeader>
              <Body size="xSmall">Points Balance</Body>
              <Body size="xSmall" style={{ marginTop: 5 }}>
                <ButtonLink onClick={() => navigate('/points-shop')}>
                  Redeem your points
                </ButtonLink>
              </Body>
            </TPLSSummaryPoints>
          ) : null}
        </TPLSSummaryStatus>
      </TPLSSummaryContent>
      {progress && <ThirdPartyLoyaltyProgress progress={progress} />}
    </TPLSSummary>
  )
}

export default ThirdPartyLoyaltySummary
