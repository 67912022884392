import styled from '@emotion/styled'
import { MenuCategory as MenuCategoryType } from '@open-tender/types'
import { contains } from '@open-tender/utils'
import {
  selectSelectedAllergenNames,
  selectSelectedTagNames,
  useAppSelector,
} from '@open-tender/cloud'
import MenuCategoryHeader from './MenuCategoryHeader'
import MenuItems from './MenuItems'
import MenuItem from './MenuItem/MenuItem'

export const MenuCategoryView = styled.div`
  label: MenuCategoryView;
  width: 100%;
  max-width: ${(props) => props.theme.menuItems.container.maxWidth};
  padding: ${(props) => props.theme.menuItems.container.padding};
  margin: ${(props) => props.theme.menuItems.container.margin};
  margin-left: auto;
  margin-right: auto;
  @media (max-width: ${(props) => props.theme.breakpoints.tablet}) {
    padding: ${(props) => props.theme.menuItems.container.paddingMobile};
    margin: ${(props) => props.theme.menuItems.container.marginMobile};
    margin-left: auto;
    margin-right: auto;
  }
`

const MenuCategory = ({
  category,
  isChild,
}: {
  category: Partial<MenuCategoryType>
  isChild?: boolean
}) => {
  const { name, description } = category
  const allergenAlerts = useAppSelector(selectSelectedAllergenNames)
  const selectedTags = useAppSelector(selectSelectedTagNames)
  const items = selectedTags.length
    ? category.items?.filter((i) => {
        const itemTags = i.tags?.split(', ') || []
        return contains(itemTags, selectedTags)
      })
    : category.items

  if (!items || !items.length) return null

  return (
    <MenuCategoryView>
      <MenuCategoryHeader
        title={name}
        subtitle={description}
        isChild={isChild}
      />
      <MenuItems>
        {items.map((item, index) => (
          <MenuItem
            key={`${item.id}-${index}`}
            item={item}
            favorite={item.favorite}
            allergenAlerts={allergenAlerts}
          />
        ))}
      </MenuItems>
    </MenuCategoryView>
  )
}

export default MenuCategory
