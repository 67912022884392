import styled from '@emotion/styled'
import { LoyaltyPerk, LoyaltyProgram } from '@open-tender/types'
import { LoyaltyHeader, LoyaltyRewards } from 'components'
import LoyaltyVisitsPizzas from './LoyaltyVisitsPizzas'
import { formatDollars, formatQuantity } from '@open-tender/utils'
import { LoyaltyVisitsPizzasCount } from './LoyaltyVisitsPizzas/LoyaltyVisitsPizzas.styled'

const LoyaltyVisitsView = styled.div``

const LoyaltyVisits = ({
  program,
  perk,
  to,
}: {
  program: LoyaltyProgram
  perk?: LoyaltyPerk | null
  to?: string
}) => {
  const { name, description, loyalty_type, spend_name } = program
  const { progress, threshold, rewards } = perk || {}

  const isDollars = loyalty_type === 'DOLLARS'
  const fmt = isDollars
    ? (t: number) => formatDollars(`${t}`, '', 0)
    : (t: number) => formatQuantity(`${t}`)
  const suffix = isDollars ? '' : ` ${spend_name}`

  return (
    <LoyaltyVisitsView>
      <LoyaltyHeader title={name} subtitle={description} />
      {/* {credit && (
        <LoyaltyStatus
          count={progress ? progress.replace('.00', '') : 0}
          name={spend_name}
        >
          {to && <SeeMoreLink text="See Details" to={to} />}
        </LoyaltyStatus>
      )} */}
      {progress && threshold ? (
        <>
          <LoyaltyVisitsPizzas threshold={threshold} progress={progress} />
          <p>
            <LoyaltyVisitsPizzasCount>
              {fmt(parseInt(threshold) - parseInt(progress))}
            </LoyaltyVisitsPizzasCount>
            {suffix} away from your next reward!
          </p>
        </>
      ) : null}
      {rewards && <LoyaltyRewards rewards={rewards} />}
    </LoyaltyVisitsView>
  )
}

export default LoyaltyVisits
