import { useMemo } from 'react'
import styled from '@emotion/styled'
import { CartItem } from '@open-tender/types'
import { makeMenuItemLookup, makeUpsellItems } from '@open-tender/utils'
import {
  selectCartIds,
  selectContentSection,
  selectMenu,
  useAppSelector,
} from '@open-tender/cloud'
import { Body, Heading, UpsellItems } from 'components'

const SidebarUpsellView = styled.div`
  flex-grow: 0;
  width: 100%;
  padding: 2rem 0 0;
`

const SidebarUpsellHeader = styled.div`
  margin: 0 2rem 0.5rem;
`

const SidebarUpsellTitle = styled(Heading)`
  display: block;
  font-size: ${(props) => props.theme.fonts.sizes.big};
  @media (max-width: ${(props) => props.theme.breakpoints.mobile}) {
    font-size: ${(props) => props.theme.fonts.sizes.big};
  }
`

const SidebarUpsellSubtitle = styled(Body)`
  display: block;
  margin: 0.75rem 0 0;
  font-size: ${(props) => props.theme.fonts.sizes.small};
  @media (max-width: ${(props) => props.theme.breakpoints.mobile}) {
    margin: 0.5rem 0 0;
    font-size: ${(props) => props.theme.fonts.sizes.xSmall};
  }
`

const SidebarUpsell = () => {
  const cartIds = useAppSelector(selectCartIds)
  const { categories, soldOut } = useAppSelector(selectMenu)
  const itemLookup = useMemo(() => makeMenuItemLookup(categories), [categories])
  const upsells = useAppSelector(selectContentSection('upsells'))
  const { show, title, subtitle } = upsells?.cart || {}

  if (!show) return null

  const menuItems = cartIds.map((id) => itemLookup[id ?? 0])
  const upsellItemIds: number[] = menuItems.reduce(
    (arr, i) => [...arr, ...(i?.upsell_items || i?.upsellItems || [])],
    []
  )
  const uniqueIds = Array.from(new Set(upsellItemIds))
  const itemIds = uniqueIds.filter(
    (id) => !cartIds.includes(id) && !soldOut.includes(id)
  )
  const upsellItems = makeUpsellItems(itemIds, itemLookup)

  if (!upsellItems.length) return null

  return (
    <SidebarUpsellView>
      <SidebarUpsellHeader>
        <SidebarUpsellTitle>{title}</SidebarUpsellTitle>
        {subtitle && <SidebarUpsellSubtitle>{subtitle}</SidebarUpsellSubtitle>}
      </SidebarUpsellHeader>
      <UpsellItems upsellItems={upsellItems as CartItem[]} isSmall={true} />
    </SidebarUpsellView>
  )
}

export default SidebarUpsell
