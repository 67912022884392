import { useNavigate } from 'react-router-dom'
import styled from '@emotion/styled'
import { OrderType, RevenueCenter, ServiceType } from '@open-tender/types'
import { makeRevenueCenterMsg } from '@open-tender/utils'
import {
  openModal,
  selectAutoSelect,
  selectContent,
  selectGroupOrder,
  selectIsGroupOrder,
  selectOrder,
  setAddress,
  setOrderServiceType,
  setRequestedAt,
  setRevenueCenter,
  useAppDispatch,
  useAppSelector,
} from '@open-tender/cloud'
import { Button } from 'components'
import Text from 'components/Text'

const RevenueCenterOrderView = styled.div`
  margin: 1rem 0 0;
  @media (max-width: ${(props) => props.theme.breakpoints.mobile}) {
    margin: 1rem 0 0;
  }
`

const RevenueCenterOrderMessage = styled(Text)`
  font-size: ${(props) => props.theme.fonts.sizes.small};
  line-height: ${(props) => props.theme.fonts.body.lineHeight};
  @media (max-width: ${(props) => props.theme.breakpoints.mobile}) {
    font-size: ${(props) => props.theme.fonts.sizes.xSmall};
  }
`

const RevenueCenterOrderButtons = styled.div`
  margin-top: 0.5rem;
  button {
    margin: 1rem 1rem 0 0;
    &:last-of-type {
      margin-right: 0;
    }
  }
`

const RevenueCenterChange = ({ autoSelect }: { autoSelect?: boolean }) => {
  const navigate = useNavigate()
  if (autoSelect) return null

  return (
    <RevenueCenterOrderButtons>
      <Button onClick={() => navigate(`/locations`)} size="small">
        Change Location
      </Button>
    </RevenueCenterOrderButtons>
  )
}

const RevenueCenterChoose = ({
  revenueCenter,
  serviceType,
  orderType,
}: {
  revenueCenter: RevenueCenter
  serviceType: ServiceType
  orderType: OrderType
}) => {
  const dispatch = useAppDispatch()
  const navigate = useNavigate()
  const {
    slug,
    revenue_center_type: rcType,
    is_outpost: isOutpost,
    address,
    first_times,
    order_times,
    status,
  } = revenueCenter
  const menuSlug = `/menu/${slug}`
  const firstTimes = first_times ? first_times[serviceType] : null
  const orderTimes = order_times ? order_times[serviceType] : null
  const { cartId } = useAppSelector(selectGroupOrder)
  const isGroupOrder = useAppSelector(selectIsGroupOrder)

  if (!firstTimes && !orderTimes) return null

  if (status !== 'OPEN') return null

  const hasAsap = firstTimes && firstTimes.has_asap
  const isCatering = rcType === 'CATERING'

  const orderAsap = () => {
    if (!hasAsap) return
    dispatch(setRequestedAt('asap'))
    dispatch(setRevenueCenter(revenueCenter))
    dispatch(setOrderServiceType({ orderType: rcType, serviceType, isOutpost }))
    if (isOutpost) dispatch(setAddress(address))
    navigate(menuSlug)
  }

  const orderLater = () => {
    const args = {
      revenueCenter,
      serviceType,
      orderType,
      isGroupOrder: isGroupOrder || cartId ? true : false,
      focusFirst: true,
      skipClose: true,
      style: orderTimes ? { alignItems: 'flex-start' } : {},
    }
    dispatch(openModal({ type: 'requestedAt', args }))
  }

  return (
    <RevenueCenterOrderButtons>
      {!isCatering && firstTimes && (
        <Button onClick={orderAsap} disabled={!hasAsap} size="small">
          Order Now
        </Button>
      )}
      <Button
        onClick={orderLater}
        size="small"
        color={isCatering || !firstTimes ? 'primary' : 'secondary'}
      >
        {isCatering ? 'Order from Here' : 'Order for Later'}
      </Button>
    </RevenueCenterOrderButtons>
  )
}

export const RevenueCenterOrder = ({
  revenueCenter,
  isMenu,
  isLanding,
}: {
  revenueCenter: RevenueCenter
  isMenu?: boolean
  isOrder?: boolean
  isLanding?: boolean
}) => {
  const { orderType, serviceType, requestedAt } = useAppSelector(selectOrder)
  const { cartId } = useAppSelector(selectGroupOrder)
  const hasGroupOrdering = revenueCenter && revenueCenter.group_ordering
  const autoSelect = useAppSelector(selectAutoSelect)
  const { revenueCenters: rcConfig } = useAppSelector(selectContent) || {}
  const { statusMessages } = rcConfig || {}
  const msg =
    statusMessages &&
    makeRevenueCenterMsg(
      revenueCenter,
      serviceType ?? 'WALKIN',
      requestedAt ?? 'asap',
      statusMessages as any
    )
  const showMsg = msg?.message && !isMenu ? true : false

  return (
    <RevenueCenterOrderView>
      {cartId && !hasGroupOrdering ? (
        <RevenueCenterOrderMessage>
          This location does not offer group ordering.
        </RevenueCenterOrderMessage>
      ) : (
        <>
          {showMsg && (
            <RevenueCenterOrderMessage as="p" color="alert">
              {msg?.message}
            </RevenueCenterOrderMessage>
          )}
          {isMenu ? (
            <RevenueCenterChange autoSelect={autoSelect} />
          ) : isLanding ? null : serviceType && orderType ? (
            <RevenueCenterChoose
              revenueCenter={revenueCenter}
              serviceType={serviceType}
              orderType={orderType}
            />
          ) : null}
        </>
      )}
    </RevenueCenterOrderView>
  )
}

export default RevenueCenterOrder
