import { isMobileOnly } from 'react-device-detect'
import { useTheme } from '@emotion/react'
import { RevenueCenter as RevenueCenterType } from '@open-tender/types'
import { stripTags } from '@open-tender/utils'
import {
  selectDisplaySettings,
  selectGroupOrder,
  useAppSelector,
} from '@open-tender/cloud'
import { Checkmark } from 'components'
import { AlertCircle, Clock, MapPin, Phone } from 'components/icons'
import RevenueCenterOrder from './RevenueCenterOrder'
import RevenueCenterAction from './RevenueCenterAction'
import {
  RevenueCenterActions,
  RevenueCenterCheckmark,
  RevenueCenterContent,
  RevenueCenterDesc,
  RevenueCenterDistance,
  RevenueCenterFlex,
  RevenueCenterFlexContent,
  RevenueCenterHeader,
  RevenueCenterImage,
  RevenueCenterTitle,
  RevenueCenterView,
} from './RevenueCenter.styled'

const RevenueCenter = ({
  revenueCenter,
  showImage,
  isActive = false,
  isMenu,
  isLanding,
  style = undefined,
}: {
  revenueCenter: RevenueCenterType
  showImage?: boolean
  isActive?: boolean
  isMenu?: boolean
  isLanding?: boolean
  style?: React.CSSProperties
}) => {
  const theme = useTheme()
  const hasBox = theme.cards.default.bgColor !== 'transparent'
  const { cartGuest } = useAppSelector(selectGroupOrder)
  const { storePhone } = useAppSelector(selectDisplaySettings) || {
    storePhone: true,
  }
  const { address, images, hours, is_outpost, description } = revenueCenter
  const smallImg = images?.find((i) => i.type === 'SMALL_IMAGE')
  const largeImg = images?.find((i) => i.type === 'SMALL_IMAGE')
  const bgImage = smallImg?.url || largeImg?.url
  const bgStyle = bgImage ? { backgroundImage: `url(${bgImage}` } : undefined
  const phoneUrl = address.phone ? `tel:${address.phone}` : null
  const desc = description ? stripTags(description) : null
  const hoursDesc = hours.description ? stripTags(hours.description) : null
  const iconProps = { size: 14 }
  const hoursDescIcon = is_outpost ? (
    <AlertCircle {...iconProps} />
  ) : (
    <Clock {...iconProps} />
  )
  const distance =
    revenueCenter.distance !== null && revenueCenter.distance !== undefined
      ? revenueCenter.distance
      : null

  return (
    <RevenueCenterView
      style={style}
      hasBox={hasBox}
      isMenu={isMenu}
      isActive={isActive}
    >
      {showImage && !isMobileOnly && (
        <RevenueCenterImage style={bgStyle}>&nbsp;</RevenueCenterImage>
      )}
      <RevenueCenterContent
        showImage={showImage && !isMobileOnly}
        hasBox={hasBox}
      >
        <div>
          <RevenueCenterFlex>
            <RevenueCenterFlexContent>
              <RevenueCenterHeader>
                <RevenueCenterTitle as="p" className="title">
                  {revenueCenter.name}
                </RevenueCenterTitle>
                {isActive && (
                  <RevenueCenterCheckmark>
                    <Checkmark />
                  </RevenueCenterCheckmark>
                )}
              </RevenueCenterHeader>
              <RevenueCenterActions>
                <a
                  href={revenueCenter.directions_url || ''}
                  rel="noopener noreferrer"
                  target="_blank"
                >
                  <RevenueCenterAction
                    icon={<MapPin {...iconProps} />}
                    text={
                      <span>
                        {address.street}{' '}
                        {distance !== null && (
                          <>
                            &bull;{' '}
                            <RevenueCenterDistance>
                              {distance.toFixed(2)} miles away
                            </RevenueCenterDistance>
                          </>
                        )}
                      </span>
                    }
                  />
                </a>
                {storePhone && phoneUrl && !isMobileOnly && (
                  <a href={phoneUrl} rel="noopener noreferrer" target="_blank">
                    <RevenueCenterAction
                      icon={<Phone {...iconProps} />}
                      text={address.phone}
                    />
                  </a>
                )}
                {hoursDesc && (
                  <RevenueCenterAction
                    icon={hoursDescIcon}
                    text={hoursDesc}
                    arrow={null}
                  />
                )}
              </RevenueCenterActions>
            </RevenueCenterFlexContent>
          </RevenueCenterFlex>
          {desc && (
            <RevenueCenterDesc>
              <p>{desc}</p>
            </RevenueCenterDesc>
          )}
          {!cartGuest && (
            <RevenueCenterOrder
              revenueCenter={revenueCenter}
              isMenu={isMenu}
              isLanding={isLanding}
            />
          )}
        </div>
      </RevenueCenterContent>
    </RevenueCenterView>
  )
}

export default RevenueCenter
