import range from 'lodash/range'
import sample from 'lodash/sample'
import { CSSProperties } from 'react'

import { lightSquareSize, normalSquareSize, extraSquareSize } from './config'
import { Amount, Coverage } from './types'

export const selectItemsWithEqualDistribution = (
  min: number,
  max: number,
  cellsCount: number,
  coverage: Coverage
) => {
  const coverageCount = cellsCount / (coverage === 'whole' ? 1 : 2)
  const itemsRange = range(min, max + 1)
  const step = Math.ceil(itemsRange.length / coverageCount)
  const selectedItems: number[] = []

  for (let i = 0; i < coverageCount; i++) {
    const startIndex = i * step
    const endIndex = (i + 1) * step
    selectedItems.push(sample(itemsRange.slice(startIndex, endIndex)) as number)
  }

  return selectedItems
}

export const getCoverageMinMaxMap = (
  cellsCount: number
): Record<Coverage, { min: number; max: number }> => ({
  '1st-half': { min: cellsCount / 2, max: cellsCount - 1 },
  '2nd-half': { min: 0, max: cellsCount / 2 - 1 },
  whole: { min: 0, max: cellsCount - 1 },
})

export const toppingAlignItems: Record<number, CSSProperties['alignItems']> = {
  0: 'flex-start',
  1: 'center',
  2: 'flex-end',
  3: 'flex-end',
  4: 'flex-start',
}

export const toppingJustifyContent: Record<
  number,
  CSSProperties['justifyContent']
> = {
  0: 'flex-end',
  1: 'center',
  2: 'flex-start',
  3: 'flex-end',
  4: 'flex-start',
}

export const squareSizeMap: Record<Amount, number> = {
  extra: extraSquareSize,
  normal: normalSquareSize,
  light: lightSquareSize,
}
