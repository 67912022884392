import { ReactElement, useEffect, useRef, useState } from 'react'
import styled from '@emotion/styled'
import { isMobile } from 'react-device-detect'
import { CartItem } from '@open-tender/types'
import { useMenuItem } from '@open-tender/utils'
import {
  selectCustomer,
  selectCustomerFavorites,
  selectDisplaySettings,
  selectGroupOrder,
  useAppSelector,
} from '@open-tender/cloud'
import {
  Body,
  Heading,
  MenuItemFavorite,
  // MenuItemTagsAllergens,
  MenuItemTagsAllergensAlt,
} from 'components'
import { XCircle } from 'components/icons'
import MenuItemPrice from './MenuItemPrice'
import MenuItemSelections from './MenuItemSelections'

const MenuItemHeaderView = styled.div`
  background-color: ${(props) => props.theme.bgColors.primary};
  transition: ${(props) => props.theme.links.transition};
`

const MenuItemHeaderContainer = styled.div``

const MenuItemHeaderPlaceholder = styled.div`
  height: 4.5rem;
`

const MenuItemScroll = styled.div<{
  stuck?: boolean
}>`
  position: absolute;
  z-index: 10;
  top: 0;
  left: 0;
  right: 0;
  height: 4.5rem;
  padding-left: ${(props) => props.theme.item.desktop.padding};
  transition: ${(props) => props.theme.links.transition};
  background-color: ${(props) => props.theme.bgColors.primary};
  opacity: ${(props) => (props.stuck ? '1' : '0')};
  visibility: ${(props) => (props.stuck ? 'visible' : 'hidden')};
  display: flex;
  justify-content: space-between;
  align-items: center;
  @media (max-width: ${(props) => props.theme.breakpoints.tablet}) {
    padding-left: ${(props) => props.theme.item.mobile.padding};
  }
`

const MenuItemScrollInfo = styled.div`
  flex: 1 1 auto;
`

const MenuItemScrollName = styled(Heading)`
  display: block;
  margin-left: -0.1rem;
  line-height: 1;
`

const MenuItemScrollPrice = styled.div`
  flex: 0 0 auto;
  display: flex;
  justify-content: flex-end;
  align-items: center;
`

const MenuItemScrollClose = styled.button`
  display: block;
  width: 4.5rem;
  margin: 0 0 0 1rem;
  height: 4.5rem;
  display: flex;
  justify-content: center;
  align-items: center;
  color: ${(props) => props.theme.links.dark.color};
  @media (max-width: ${(props) => props.theme.breakpoints.tablet}) {
    margin: 0;
  }

  &:hover {
    color: ${(props) => props.theme.links.dark.hover};
  }
`

const MenuItemInfo = styled.div`
  label: MenuItemInfo;
  transition: ${(props) => props.theme.links.transition};
  padding: 2rem ${(props) => props.theme.item.desktop.padding};
  padding-bottom: 0;
  @media (max-width: ${(props) => props.theme.breakpoints.tablet}) {
    padding: 2rem ${(props) => props.theme.item.mobile.padding};
    padding-bottom: 0;
  }

  &.isCustomize {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
`

const MenuItemDetails = styled.div`
  label: MenuItemDetails;
  padding: 0 ${(props) => props.theme.item.desktop.padding};
  @media (max-width: ${(props) => props.theme.breakpoints.tablet}) {
    padding: 0 ${(props) => props.theme.item.mobile.padding};
  }
`

const MenuItemNameContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`

const MenuItemName = styled(Heading)`
  display: block;
  margin-left: -0.1rem;
  transition: ${(props) => props.theme.links.transition};
`

const MenuItemDesc = styled(Body)`
  margin: 1rem 0 0;
  @media (max-width: ${(props) => props.theme.breakpoints.mobile}) {
    font-size: ${(props) => props.theme.fonts.sizes.small};
  }
`

const MenuItemHeader = ({
  cancel,
  builtItem,
  hasCustomize,
  isCustomize,
  setIsCustomize,
  scrollContainer,
  topOffset,
}: {
  cancel: () => void
  builtItem: CartItem
  displaySettings?: any
  pointsIcon?: ReactElement
  hasCustomize?: boolean
  isCustomize?: boolean
  setIsCustomize: (isCustomize: boolean) => void
  scrollContainer: any
  topOffset: number | null
}) => {
  const headerRef = useRef<HTMLDivElement | null>(null)
  const [stuck, setStuck] = useState(false)
  const { auth } = useAppSelector(selectCustomer)
  const { lookup } = useAppSelector(selectCustomerFavorites)
  const { cartId } = useAppSelector(selectGroupOrder)
  const displaySettings = useAppSelector(selectDisplaySettings)
  const item = useMenuItem(builtItem, auth, lookup, cartId, displaySettings)
  const {
    name,
    description,
    displayPrice,
    displayCals,
    totalPoints,
    hasGroups,
    nonSizeGroups,
    favoriteId,
    isIncomplete,
    requiresCustomization,
    tags,
    allergens,
  } = item
  const hasTagsAllergens = tags.length || allergens.length ? true : false

  useEffect(() => {
    const handleScroll = () => {
      if (headerRef.current && topOffset !== null) {
        setStuck(headerRef.current.getBoundingClientRect().top <= topOffset - 1)
      }
    }
    scrollContainer && scrollContainer.addEventListener('scroll', handleScroll)
    return () => {
      scrollContainer &&
        scrollContainer.removeEventListener('scroll', () => handleScroll)
    }
  }, [topOffset, scrollContainer, isCustomize])

  return (
    <MenuItemHeaderView ref={headerRef}>
      <MenuItemScroll stuck={hasCustomize ? isCustomize : stuck}>
        <MenuItemScrollInfo>
          <MenuItemScrollName size={isMobile ? 'main' : 'big'}>
            {name}
          </MenuItemScrollName>
        </MenuItemScrollInfo>
        <MenuItemScrollPrice>
          <MenuItemPrice
            price={displayPrice}
            cals={displayCals}
            points={isMobile ? null : totalPoints}
            size={isMobile ? 'small' : 'main'}
          />
          <MenuItemScrollClose
            aria-label="Cancel & Return to Menu"
            onClick={cancel}
          >
            <XCircle size={24} />
          </MenuItemScrollClose>
        </MenuItemScrollPrice>
      </MenuItemScroll>
      {!isCustomize ? (
        <MenuItemHeaderContainer>
          <MenuItemInfo>
            <MenuItemNameContainer>
              <MenuItemName size={isMobile ? 'xBig' : 'xxBig'} as="p">
                {name}
              </MenuItemName>
              {auth && (
                <MenuItemFavorite
                  size={isMobile ? 18 : 24}
                  favoriteId={favoriteId}
                  builtItem={builtItem as any}
                  disabled={isIncomplete}
                />
              )}
            </MenuItemNameContainer>
            <MenuItemPrice
              price={displayPrice}
              cals={displayCals}
              points={totalPoints}
              size={isMobile ? 'main' : 'big'}
              style={{ margin: '1rem 0 0' }}
            />
          </MenuItemInfo>
          <MenuItemDetails>
            {/* <MenuItemTagsAllergens {...item} /> */}
            {description ? (
              <MenuItemDesc as="p">{description}</MenuItemDesc>
            ) : null}
            {hasTagsAllergens && (
              <MenuItemTagsAllergensAlt tags={tags} allergens={allergens} />
            )}
          </MenuItemDetails>
          {hasCustomize && hasGroups && nonSizeGroups ? (
            <MenuItemSelections
              item={item}
              groups={nonSizeGroups}
              requiresCustomization={requiresCustomization}
              setIsCustomize={setIsCustomize}
            />
          ) : null}
        </MenuItemHeaderContainer>
      ) : hasCustomize ? (
        <MenuItemHeaderPlaceholder />
      ) : null}
    </MenuItemHeaderView>
  )
}

export default MenuItemHeader
