import { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { isMobile } from 'react-device-detect'
import styled from '@emotion/styled'
import {
  openModal,
  resetCheckout,
  resetGroupOrder,
  resetOrderType,
  resetRevenueCenters,
  selectContent,
  selectGroupOrder,
  selectSettings,
  setIsGroupOrder,
  setOrderServiceType,
  useAppDispatch,
  useAppSelector,
} from '@open-tender/cloud'
import {
  Coffee,
  DollarSign,
  Flag,
  Gift,
  ShoppingBag,
  ShoppingCart,
  Truck,
  UserPlus,
  Users,
} from 'components/icons'
import { Message, NavButtons } from 'components'
import { NavButtonProps } from '../../NavButtons/NavButton'
import { ServiceType } from '@open-tender/types'

const OrderTypesView = styled.div<{ showDesc?: boolean; isMobile?: boolean }>`

  & > div:first-of-type button {
    ${(props) =>
      !props.showDesc ? `height: ${props.isMobile ? '5.6rem' : '6rem'};` : ``}

`

const OrderTypes = () => {
  const dispatch = useAppDispatch()
  const navigate = useNavigate()
  const { orderType: orderTypeContent } = useAppSelector(selectContent) || {}
  const contentTypes = orderTypeContent?.orderTypes
  const { orderTypes } = useAppSelector(selectSettings) || {}
  const hasOrderTypes = orderTypes && orderTypes.length > 0
  const { cartGuest } = useAppSelector(selectGroupOrder)
  const { cartGuestId } = cartGuest || {}
  const { showDescriptions, showDescriptionsMobile } = orderTypeContent || {}
  const showDesc = isMobile ? showDescriptionsMobile : showDescriptions
  // const asLinks = ['GIFT_CARDS', 'DONATIONS']
  let serviceTypes: ServiceType[] = []
  if (orderTypes?.includes('PICKUP')) serviceTypes.push('PICKUP')
  if (orderTypes?.includes('DELIVERY')) serviceTypes.push('DELIVERY')

  useEffect(() => {
    dispatch(resetRevenueCenters())
    dispatch(resetOrderType())
    dispatch(resetCheckout())
    dispatch(setIsGroupOrder(false))
  }, [dispatch])

  useEffect(() => {
    if (cartGuestId) dispatch(resetGroupOrder())
  }, [dispatch, cartGuestId])

  const handleOutpost = () => {
    dispatch(
      setOrderServiceType({
        orderType: 'OLO',
        serviceType: 'PICKUP',
        isOutpost: true,
      })
    )
    navigate('/locations')
  }

  const handleWalkin = () => {
    dispatch(setOrderServiceType({ orderType: 'OLO', serviceType: 'WALKIN' }))
    navigate('/locations')
  }

  const handlePickup = () => {
    dispatch(setOrderServiceType({ orderType: 'OLO', serviceType: 'PICKUP' }))
    navigate('/locations')
  }

  const handleDelivery = () => {
    dispatch(setOrderServiceType({ orderType: 'OLO', serviceType: 'DELIVERY' }))
    navigate('/locations')
  }

  const handleGroupOrder = () => {
    dispatch(openModal({ type: 'groupOrderType', args: { serviceTypes } }))
  }

  const handleCatering = () => {
    dispatch(
      setOrderServiceType({ orderType: 'CATERING', serviceType: 'DELIVERY' })
    )
    navigate('/catering-address')
  }

  const handleMerch = () => {
    dispatch(
      setOrderServiceType({ orderType: 'MERCH', serviceType: 'DELIVERY' })
    )
    navigate('/locations')
  }

  const handleGiftCards = () => {
    navigate('/gift-cards')
  }

  const handleDonations = () => {
    navigate('/donations')
  }

  const handlers = {
    OUTPOST: handleOutpost,
    WALKIN: handleWalkin,
    PICKUP: handlePickup,
    DELIVERY: handleDelivery,
    GROUP: handleGroupOrder,
    CATERING: handleCatering,
    MERCH: handleMerch,
    GIFT_CARDS: handleGiftCards,
    DONATIONS: handleDonations,
  }

  const iconProps = isMobile ? { size: 18 } : { size: 20 }

  const icons = {
    OUTPOST: <Flag {...iconProps} />,
    WALKIN: <Coffee {...iconProps} />,
    PICKUP: <ShoppingBag {...iconProps} />,
    DELIVERY: <Truck {...iconProps} />,
    GROUP: <UserPlus {...iconProps} />,
    CATERING: <Users {...iconProps} />,
    MERCH: <ShoppingCart {...iconProps} />,
    GIFT_CARDS: <Gift {...iconProps} />,
    DONATIONS: <DollarSign {...iconProps} />,
  }

  if (!orderTypes || !contentTypes) return null

  const buttons: NavButtonProps[] = orderTypes
    .map((orderType) => ({
      ...contentTypes[orderType],
      icon: icons[orderType],
      onClick: handlers[orderType],
    }))
    .map((orderType) => ({
      ...orderType,
      subtitle: showDesc ? orderType.subtitle : null,
    }))

  return (
    <div>
      {hasOrderTypes ? (
        <OrderTypesView showDesc={showDesc} isMobile={isMobile}>
          <NavButtons buttons={buttons} />
        </OrderTypesView>
      ) : (
        <Message color="error">
          This brand is not currently accepting online orders.
        </Message>
      )}
    </div>
  )
}

export default OrderTypes
