import { useState, useEffect } from 'react'
import styled from '@emotion/styled'
import {
  OrderTotals,
  CheckoutCheck,
  CheckoutErrorMessages,
  OrderDiscount,
} from '@open-tender/types'
import {
  handleCheckoutError,
  isEmpty,
  makeCheckError,
} from '@open-tender/utils'
import {
  selectBrand,
  selectCheckout,
  selectContent,
  sendCustomerVerificationEmail,
  updateForm,
  useAppDispatch,
  useAppSelector,
  validateOrder,
} from '@open-tender/cloud'
import { ButtonLink, FormError, Text } from 'components'
import CheckoutSection from './CheckoutSection'
import CheckoutButton from './CheckoutButton/CheckoutButton'

const CheckoutDiscountsView = styled.div`
  margin: 2rem 0 0;
`

const calcTotal = (totals?: OrderTotals) => {
  if (!totals) return 0.0
  const { subtotal, surcharge, discount } = totals
  if (!subtotal) return 0.0
  return parseFloat(subtotal) + parseFloat(surcharge) + parseFloat(discount)
}

const makeErrorMsg = (
  errors: CheckoutErrorMessages,
  check: CheckoutCheck | null
) => {
  if (errors && !isEmpty(errors)) {
    const customerErrors = errors?.customer
    if (!customerErrors) return null
    return typeof customerErrors !== 'string'
      ? (customerErrors.account as string)
      : null
  }
  if (!check || !check.errors) return null
  const checkError = makeCheckError(check.errors)
  const checkErrors = handleCheckoutError(checkError)
  const customerErrors = checkErrors?.customer
  if (!customerErrors) return null
  return typeof customerErrors !== 'string'
    ? (customerErrors.account as string)
    : null
}

const processErrMsg = (errMsg: string | null) => {
  if (!errMsg) return null
  if (errMsg?.includes('ORDER_VALIDATION_FAILURE: ')) {
    const [, msg] = errMsg.split('ORDER_VALIDATION_FAILURE: ')
    return msg
  }
  return errMsg
}

const CheckoutDiscounts = () => {
  const dispatch = useAppDispatch()
  const { has_thanx = false, tpls } = useAppSelector(selectBrand) || {}
  const limitOne = has_thanx || tpls === 'COMO' || tpls === 'PUNCHH'
  const isPunchh = tpls === 'PUNCHH'
  const [errMsg, setErrMsg] = useState<string | null>(null)
  const { check, form, loading, errors } = useAppSelector(selectCheckout)
  const { checkout: config } = useAppSelector(selectContent) || {}
  const { customer_id, is_verified } = check?.customer || {}
  const total = calcTotal(check?.totals)
  const [pendingDiscount, setPendingDiscount] = useState<number | null>(null)
  const discountIds = form.discounts.map((i) => i.id).filter((i) => i !== 0)
  const fullErrMsg = pendingDiscount ? null : makeErrorMsg(errors, check)
  const discountErrMsg = processErrMsg(fullErrMsg)
  const pointsBalance = check?.config.points?.balance || 0

  // add initial auto applied discounts
  useEffect(() => {
    const initialDiscounts = check?.discounts
      .filter((i) => !i.is_optional)
      .filter((i) => !form.discounts.find((a) => i.id === a.id))
      .map((i) => ({ id: i.id, ext_id: i.ext_id || '' }))
    if (initialDiscounts?.length) {
      const discounts = [...form.discounts, ...initialDiscounts]
      dispatch(updateForm({ discounts }))
      dispatch(validateOrder())
    }
  }, [check?.discounts, form.discounts, dispatch])

  useEffect(() => {
    if (loading !== 'pending') setPendingDiscount(null)
  }, [loading])

  useEffect(() => {
    setErrMsg(discountErrMsg || null)
  }, [discountErrMsg])

  useEffect(() => {
    if (errMsg) {
      dispatch(updateForm({ discounts: [] }))
      window.scrollTo(0, 0)
    }
  }, [errMsg, dispatch])

  const discountsOptional = check?.config.discounts.length
    ? check.config.discounts
    : null
  if (!discountsOptional) return null

  const applyDiscount = (discountId: number, extId: string) => {
    setPendingDiscount(discountId)
    setErrMsg(null)
    const newDiscount = { id: discountId, ext_id: extId || '' }
    if (limitOne) {
      dispatch(updateForm({ discounts: [newDiscount] }))
    } else {
      dispatch(updateForm({ discounts: [...form.discounts, newDiscount] }))
    }
    dispatch(validateOrder())
  }

  const removeDiscount = (discountId: number) => {
    const filtered = form.discounts.filter((i) => i.id !== discountId)
    dispatch(updateForm({ discounts: filtered }))
    dispatch(validateOrder())
  }

  const verifyAccount = () => {
    const linkUrl = `${window.location.origin}/verify`
    dispatch(sendCustomerVerificationEmail(linkUrl))
  }

  const makeDiscountButton = (i: OrderDiscount) => {
    const title = i.points
      ? `${i.name} - ${i.points} points`
      : i.title || i.name
    const isApplied = discountIds.includes(i.id)
    const isPending = i.id === pendingDiscount
    const missingAccount =
      ['ACCOUNT', 'VERIFIED'].includes(i.auth_type || '') && !customer_id
    const missingVerified = i.auth_type === 'VERIFIED' && !is_verified
    const onPress = isApplied
      ? () => removeDiscount(i.id)
      : () => applyDiscount(i.id, i.ext_id || '')
    const disabled = isApplied
      ? !i.is_optional
      : missingAccount || missingVerified || total <= 0.0
    const notRedeemable = i.is_redeemable === false

    return (
      <CheckoutButton
        key={`${i.id}-${i.ext_id}`}
        title={title}
        onPress={isPending ? undefined : onPress}
        isApplied={isApplied}
        disabled={disabled || notRedeemable}
        subtitle={
          !i.is_optional ? (
            'Credit has automatically been applied to your order.'
          ) : missingAccount ? (
            <Text size="xSmall" color="error">
              Requires an account. Please create an account to enable this
              discount.
            </Text>
          ) : missingVerified ? (
            <Text size="xSmall" color="error">
              Requires a verified account.{' '}
              <ButtonLink onClick={verifyAccount}>
                Click here to send a verification email
              </ButtonLink>{' '}
              and then refresh this page after {"you've"} verified your account.
            </Text>
          ) : i.per_order === 1 ? (
            'Cannot be used with any other discounts'
          ) : notRedeemable ? (
            <Text size="xSmall" color="error">
              To redeem this offer, please add the applicable items to your
              cart.
            </Text>
          ) : (
            i.description || null
          )
        }
      />
    )
  }

  const loyalty = discountsOptional.filter((i) => i.discount_type === 'LOYALTY')
  const rewards = discountsOptional.filter((i) => i.discount_type === 'REWARD')
  const deals = discountsOptional.filter((i) => i.discount_type === 'DEAL')
  const other = isPunchh
    ? discountsOptional.filter(
        (i) => i.discount_type === 'DISCOUNT' && i.tpls_type === null
      )
    : discountsOptional.filter((i) => i.discount_type === 'DISCOUNT')
  const pointsOffers = isPunchh
    ? discountsOptional.filter(
        (i) =>
          i.discount_type === 'DISCOUNT' &&
          i.tpls_type === 'REDEEMABLE' &&
          i.points &&
          pointsBalance > i.points
      )
    : []
  const allDiscounts = [...loyalty, ...rewards, ...deals, ...other]
  const redeemable = allDiscounts.filter((i) => i.is_redeemable !== false)
  const notRedeemable = allDiscounts.filter((i) => i.is_redeemable === false)
  const hasRewards = redeemable.length > 0 || notRedeemable.length > 0
  const hasPoints = pointsOffers.length > 0

  return (
    <>
      {hasRewards && (
        <CheckoutSection title={config?.discounts.title}>
          <FormError errMsg={errMsg} />
          <CheckoutDiscountsView>
            {redeemable.map((i) => makeDiscountButton(i))}
            {notRedeemable.map((i) => makeDiscountButton(i))}
          </CheckoutDiscountsView>
        </CheckoutSection>
      )}
      {hasPoints && (
        <CheckoutSection
          title={`${config?.pointsShop.title} - ${pointsBalance} Points Available`}
        >
          <FormError errMsg={errMsg} />
          <CheckoutDiscountsView>
            {pointsOffers.map((i) => makeDiscountButton(i))}
          </CheckoutDiscountsView>
        </CheckoutSection>
      )}
    </>
  )
}

export default CheckoutDiscounts
